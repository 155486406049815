import Header from "./Header";
import Content from "./Content";
import { Grid } from "@mui/material";
import Stylesheet from "reactjs-stylesheet";

const CadastrarMembros = ({
    isMobile,
    screenWidth,
    allMembros,

}) => {
    return (
        <Grid item xs sx={screenWidth <= 1400 ? styles.contentSmallScreen : isMobile ? styles.contentMobile : styles.content}>
            <Grid container spacing={0}>
                <Header isMobile={true} />
                <Content
                    screenWidth={screenWidth}
                    allMembros={allMembros}
                />
            </Grid>
        </Grid >
    );
};

export default CadastrarMembros;

const styles = Stylesheet.create({
    content: {
        margin: '40px 50px 16px 70px'
    },
    contentSmallScreen: {
        margin: '45px 25px'
    },
    contentMobile: {
        margin: '20px 10px'
    }
});