import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Menu from "./componentes/menu";
import MenuMobile from "./componentes/menuMobile";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCalendarDays, faCalendarCheck } from '@fortawesome/free-solid-svg-icons'
import RecebiveisScreen from "./Recebiveis/Recebiveis";
import { format } from "date-fns";

const Recebiveis = () => {

  const now = new Date();
  const navigate = useNavigate();
  const [screenWidth, setScreenWidth] = useState(null);
  const [dadosX, setDadosX] = useState([]);
  const [dadosREF, setDADOSREF] = useState(null);
  const [total_receber, setTotalReceber] = useState('');
  const [geralTaxas, setTotalTaxas] = useState('R$ 0,00');
  const [selectedValue, setSelectedValue] = useState(now.getMonth());
  const [ano, setAno] = useState(now.getFullYear());
  const [dataCalendario, setDataCalendario] = useState([]);
  const [geralDebito, setGeralDebito] = useState(0);
  const [geralCredito, setGeralCredito] = useState(0);
  const [geralXuliz, setGeralXuliz] = useState(0);
  const [geralBruto, setGeralBruto] = useState(0);
  const [totalPedidos, setTotalPedidos] = useState(0);
  const [isMobile, setIsMobile] = useState(false);
  const [selectedValues, setSelectedValues] = useState([]);
  const [dtIniList, setDtIniList] = useState('');
  const [dtEndList, setDtEndList] = useState('');
  const [menuOpen, setMenuOpen] = useState(true);

  const toggleValue = async (obj) => {

    var datainicial = await getDataInicial(parseInt(selectedValue) + 1)
    var datafinal = await getDataFinal(parseInt(selectedValue) + 1)

    setSelectedValues((prevValues) => {
      // Verifica se a data selecionada já está no período atual
      const isSelected = prevValues.some(item => item.data === obj.data);

      if (prevValues[0]?.data == obj?.data) {
        setDtIniList(datainicial)
        setDtEndList(datafinal)
        return [];
      }

      if (isSelected) {
        // Se a data já está selecionada, reseta a seleção anterior e inicia um novo período
        return [obj]; // Retorna apenas a nova data como início de um novo período
      } else {
        // Se for uma nova data e não houver uma seleção anterior, começa um novo período
        const updatedValues = [obj];

        // Se já houver um valor selecionado, calcula o intervalo entre as duas datas
        if (prevValues.length === 1) {
          const [firstDay] = prevValues;
          const [start, end] = [firstDay, obj].sort((a, b) => new Date(a.data) - new Date(b.data));
          return getDaysBetween(start, end); // Calcula intervalo entre as duas datas
        }

        // Apenas a nova data selecionada é retornada se for o início de uma nova seleção
        return updatedValues;
      }
    });
  };

  // Função para calcular o intervalo entre duas datas
  const getDaysBetween = (start, end) => {
    let startDate = new Date(`${start.data}T00:00:00`);
    let endDate = new Date(`${end.data}T00:00:00`);

    setDtIniList(format(startDate, 'yyyy-MM-dd'));
    setDtEndList(format(endDate, 'yyyy-MM-dd'));

    // Garante que startDate seja menor ou igual a endDate
    if (startDate > endDate) {
      [startDate, endDate] = [endDate, startDate];
    }

    const interval = [];
    for (let date = startDate; date <= endDate; date.setDate(date.getDate() + 1)) {
      const dateString = new Date(date).toISOString().split('T')[0];

      // Encontra valores para a data atual, se disponíveis
      const found = selectedValues.find(item => item.data === dateString);
      interval.push({
        data: dateString,
        total: found ? found.total : '',
        totalPedidos: found ? found.totalPedidos : ''
      });
    }
    return interval;
  };

  useEffect(() => {
    updateDimensions();
    window.addEventListener('resize', updateDimensions);
    return () => {
      window.removeEventListener('resize', updateDimensions);
    };
  }, []);

  useEffect(() => {
    getDadosSQL()
  }, []);

  const updateDimensions = () => {
    setIsMobile(window.innerWidth <= 800); // Defina o limite de largura para o modo mobile conforme necessário
    verificarNiveisLogin(dadosX)
    setScreenWidth(window.innerWidth);

    if (window.innerWidth <= 1400 && menuOpen) {
      setMenuOpen(false)
    }
    if (window.innerWidth > 1400 && menuOpen) {
      setMenuOpen(true)
    }
  };

  const getDadosSQL = async (mesSelecionado) => {

    await getDados()

    var dados = await localStorage.getItem('login')
    dados = JSON.parse(dados);
    var idestabele = dados.idestabelecimento;
    var getTokenEstabelecimneto = await getTokenEstabelciment(idestabele);

    if (getTokenEstabelecimneto == null) {
      getTokenEstabelecimneto = dados.tokenestabelecimento;
    }

    var datainicial = await getDataInicial(mesSelecionado)
    var datafinal = await getDataFinal(mesSelecionado)

    setDtIniList(datainicial)
    setDtEndList(datafinal)

    const options = {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: new URLSearchParams({
        idempresa: '1350',
        token: getTokenEstabelecimneto,
        idvendaxuliz: '',
        metodo: 'Lista',
        idestabelecimento: idestabele,
        idvenda: '',
        datainicial: datainicial,
        datafinal: datafinal
      })
    };

    fetch('https://www.xulizsistemas.com.br/retaguarda/integracao/estabelecimento/listarecebiveisagrupado.php', options)
      // fetch('https://xuliz.com.br/painel/integracao/vendaxuliz.php', options)
      .then(response => response.json())
      .then(response => tradarDados(response, datainicial, datafinal))
      .catch(err => console.error(err));
  }

  const tradarDados = async (dadosG, datainicial, datafinal) => {

    setDADOSREF(dadosG)

    var retorno = dadosG.retorno;
    var usuarioFiltrou = false;

    if (dadosG.array_retorno) {
      usuarioFiltrou = true;
      retorno = dadosG.array_retorno

      if (dadosG.array_retorno.length == 0) {
        retorno = dadosG.retorno;
      }
    }

    var geralLiquido = 0;
    var totalDebitos = 0;
    var totalCredito = 0;
    var totalXuliz = 0;
    var totalTaxas = 0;
    var totalBruto = 0;
    var total_pedido = 0;

    var dadosCalendario = []

    for (var x = 0; x < retorno.length; x++) {

      var data = retorno[x].datavencimento;
      var datavencimento = retorno[x].datavencimento;
      data = data.split('-');
      var diaRef = data[2];
      data = data[0] + '-' + data[1];
      var resumo = retorno[x].resumo

      for (var b = 0; b < resumo.length; b++) {
        var bandeira = resumo[b].tipo;

        if (bandeira.includes("Débito")) {
          var debito = resumo[b].valor
          debito = parseFloat(debito)
          totalDebitos += debito
        }

        if (bandeira.includes("Crédito")) {
          var credito = resumo[b].valor
          credito = parseFloat(credito)
          totalCredito += credito
        }

        if (bandeira.includes("Xuliz APP")) {
          var xuliz = resumo[b].valor
          xuliz = parseFloat(xuliz)
          totalXuliz += xuliz
        }
      }


      // console.log(mesREF+'  ||| '+data+' -->'+diaRef)

      var valor = retorno[x].valor;
      valor = parseFloat(valor);

      var valorPix = resumo.find((i) => i.tipo == 'Pix')?.valor ? resumo.find((i) => i.tipo == 'Pix').valor : 0;
      valorPix = parseFloat(valorPix);

      geralLiquido = geralLiquido + valor;

      var quantidade = retorno[x].qtde
      quantidade = parseFloat(quantidade)
      total_pedido = total_pedido + quantidade;

      var valorBruto = retorno[x].valorbruto
      valorBruto = parseFloat(valorBruto)
      totalBruto = totalBruto + valorBruto;

      totalTaxas = totalTaxas + (valorBruto - valor)


      dadosCalendario.push({
        liquido: valor,
        pix: valorPix,
        diaRef: diaRef,
        data: data,
        datavencimento: datavencimento,
        qtde: retorno[x].qtde
      })


    }

    var resultadoCalendario = [];

    for (var x = 0; x < dadosCalendario.length; x++) {

      resultadoCalendario.push({
        data: dadosCalendario[x].datavencimento,
        total: dadosCalendario[x].liquido,
        totalPedidos: dadosCalendario[x].qtde,
        pix: dadosCalendario[x].pix
      })
    }

    if (usuarioFiltrou == false) {
      setDataCalendario(resultadoCalendario)
    }

    geralLiquido = geralLiquido.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
    totalDebitos = totalDebitos.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
    totalCredito = totalCredito.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
    totalXuliz = totalXuliz.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
    totalTaxas = totalTaxas.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
    totalBruto = totalBruto.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });

    setTotalReceber(geralLiquido)
    setGeralDebito(totalDebitos)
    setGeralCredito(totalCredito)
    setGeralXuliz(totalXuliz)
    setTotalTaxas(totalTaxas)
    setGeralBruto(totalBruto)
    setTotalPedidos(total_pedido)

    datainicial = datainicial.split('-')
    datainicial = datainicial[2] + "-" + datainicial[1] + "-" + datainicial[0]

    datafinal = datafinal.split('-')
    datafinal = datafinal[2] + "-" + datafinal[1] + "-" + datafinal[0]
  }

  function obterUltimoDiaDoMes(mes, ano) {
    var mesInt = parseInt(mes);
    // Criar uma nova data para o primeiro dia do mês seguinte
    var data = new Date(ano, mesInt, 0);
    // O último dia do mês é o último dia do mês anterior ao que definimos
    var ultimoDia = data.getDate();

    return ultimoDia;
  }

  const getDataFinal = async (mesSelecionado) => {

    var data = new Date();
    var ano = data.getFullYear();
    // var ano = data.getFullYear() + 1;
    var mes = String(data.getMonth() + 1).padStart(2, '0');

    if (mesSelecionado != null) {
      mes = mesSelecionado
    }

    var dia = obterUltimoDiaDoMes(mes, ano)

    // return ano + '-' + '12' + '-' + '01';
    return ano + '-' + mes + '-' + dia;
  }

  const getDataInicial = async (mesSelecionado) => {
    var data = new Date();

    // data.setDate(data.getDate() - 32);
    var ano = data.getFullYear();
    var mes = String(data.getMonth() + 1).padStart(2, '0');

    if (mesSelecionado != null) {
      mes = mesSelecionado
    }

    var dia = "01";

    // return ano + '-' + '01' + '-' + '01';
    return ano + '-' + mes + '-' + dia;
  }

  const getTokenEstabelciment = async (token) => {
    const form = new FormData();
    form.append("idempresa", "1350");
    form.append("token", "e84d7ad2-f0cc-11eb-b838-568a76eb275e");
    form.append("metodo", "ListaEstabelecimentos");


    const options = {
      method: 'POST',
    };

    options.body = form;

    var rres = await fetch('https://xuliz.com.br/painel/integracao/estabelecimento.php', options).then(response => response.json());

    var arrrayEstabelecimnto = rres.estabelecimentos;

    var tokenEstabelecimento = '';
    for (var x = 0; x < arrrayEstabelecimnto.length; x++) {
      if (arrrayEstabelecimnto[x].idestabelecimento == token) {
        tokenEstabelecimento = arrrayEstabelecimnto[x].token;
        break;
      }
    }

    return tokenEstabelecimento;
  }

  const getDados = async () => {
    var dados = await localStorage.getItem('login')

    if (dados == null) {
      navigate("/");

      return false;
    }
    dados = JSON.parse(dados)
    setDadosX(dados)
  }

  const verificarNiveisLogin = async () => {
    var idperfil = await localStorage.getItem('tipoPerfil');

    if (idperfil == "7") {
      //financeiro somente relatorios
      location.href = "./minhasvendas";
    }
  }

  const handleSelectChange = (event) => {
    setSelectedValue(event.target.value);

    var mesRef = "" + event.target.value;
    mesRef = parseInt(mesRef) + 1;

    if (mesRef < 10) {
      mesRef = "0" + mesRef;
    }

    getDadosSQL(mesRef)
  };

  const handleMonthChangeMobile = (direction) => {
    let newMes = selectedValue;

    if (direction === 'next') {
      newMes = selectedValue === 11 ? 0 : selectedValue + 1;
    } else if (direction === 'previous') {
      newMes = selectedValue === 0 ? 11 : selectedValue - 1;
    }

    setSelectedValue(newMes);
    let mesRef = (newMes + 1).toString().padStart(2, '0');
    getDadosSQL(mesRef);
  };

  if (isMobile == true) {
    return (
      <>
        <MenuMobile page="recebiveis" />

        <div style={{ height: 150, width: '100%', flexDirection: 'row', display: 'flex', padding: 10 }}>
          <div style={{ width: '100%', display: 'flex', flex: 1, margin: 5, borderRadius: 10, justifyContent: 'center', alignItems: 'center', flexDirection: 'column', backgroundColor: '#142A30' }} onClick={() => { location.href = './recebiveis' }}>
            <FontAwesomeIcon icon={faCalendarDays} style={{ fontSize: 30, color: "#FFFFFF" }} />
            <span style={{ textAlign: 'center', fontSize: 14, marginTop: 10, color: "#FFFFFF" }}>recebiveis</span>
          </div>
          <div style={{ width: '100%', backgroundColor: '#F1F1F1', display: 'flex', flex: 1, margin: 5, borderRadius: 10, justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }} onClick={() => { location.href = './resumo_recebiveis' }}>
            <FontAwesomeIcon icon={faCalendarCheck} style={{ fontSize: 30, color: "#262626" }} />
            <span style={{ textAlign: 'center', fontSize: 14, marginTop: 10, color: "#262626" }}>Resumo</span>
          </div>
        </div>

        <RecebiveisScreen
          isMobile={isMobile}
          screenWidth={screenWidth}
          mes={selectedValue}
          ano={ano}
          handleMonthChangeMobile={handleMonthChangeMobile}
          dataCalendario={dataCalendario}
          handleSelectChange={handleSelectChange}
          geralBruto={geralBruto}
          totalPedidos={totalPedidos}
          geralCredito={geralCredito}
          geralDebito={geralDebito}
          geralXuliz={geralXuliz}
          geralTaxas={geralTaxas}
          total_receber={total_receber}
          toggleValue={toggleValue}
          selectedValues={selectedValues}
          dadosREF={dadosREF}
          dtIniList={dtIniList}
          dtEndList={dtEndList}
        />
      </>
    )
  }

  return (
    <>
      <div style={{ maxWidth: '1920px', height: '100vh', display: "flex" }}>
        <div style={{ backgroundColor: '#262626' }}>
          <Menu page="recebiveis" menuOpen={menuOpen} setMenuOpen={setMenuOpen} />
        </div>
        <div style={{ overflow: 'auto', width: '100%' }}>
          <RecebiveisScreen
            isMobile={isMobile}
            screenWidth={screenWidth}
            mes={selectedValue}
            ano={ano}
            dataCalendario={dataCalendario}
            handleSelectChange={handleSelectChange}
            geralBruto={geralBruto}
            totalPedidos={totalPedidos}
            geralCredito={geralCredito}
            geralDebito={geralDebito}
            geralXuliz={geralXuliz}
            geralTaxas={geralTaxas}
            total_receber={total_receber}
            toggleValue={toggleValue}
            selectedValues={selectedValues}
            dadosREF={dadosREF}
            dtIniList={dtIniList}
            dtEndList={dtEndList}
          />
        </div>
      </div>
    </>
  )
};

export default Recebiveis;