import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { format } from "date-fns";

import waiting from '../assets/waiting.jpg';
import visa from '../assets/visa.png';
import master from '../assets/master.png';
import elo from '../assets/elo_debito.png';
import hipercard from '../assets/hipercard.png';
import Pix from '../assets/Pix.png';
import amex from '../assets/amex.png';

import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import { Grid, Typography } from "@mui/material";

import flagXulis from '../assets/flags/flagXulis.png';

import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import Stylesheet from "reactjs-stylesheet";

pdfMake.vfs = pdfFonts.pdfMake.vfs;

const ResumoVendasHoje = ({ sceenWidth }) => {
    const navigate = useNavigate();

    const [emailRef, setEmailRef] = useState('');
    const [value, setValue] = useState('');
    const [urlfotoperfil, setFotoUrl] = useState('');
    const [cashbackConta, setCashback] = useState('3');
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const selectRef = useRef(null);


    const [dadosX, setDadosX] = useState([]);
    const [rows, setRow] = useState([]);
    const [tokenEsta, setTokenEstabelecimento] = useState('');

    const [quantidadeVendas, setQuantidadeVendas] = useState('0');
    const [valorTotal, setValorTotal] = useState('0');
    const [valorLiquido, setValorLiquido] = useState('0');
    const [geralCredito, setGeralCredito] = useState('0');
    const [geralDebito, setGeralDebito] = useState('0');
    const [geralXuliz, setGeralXuliz] = useState('0');

    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [showCalendar, setShowCalendar] = useState(false);

    const [vendaInicio, setVendaInicio] = useState('');
    const [vendaFinal, setVendaFinal] = useState('');

    const [isMobile, setIsMobile] = useState(false);

    const [loading, setloading] = useState(false);
    const [filters, setFilters] = useState([
        { type: 'Bandeira', value: 'bandeira' }
    ]);

    useEffect(() => {
        updateDimensions();
        window.addEventListener('resize', updateDimensions);

        return () => {
            window.removeEventListener('resize', updateDimensions);
        };
    }, []);

    const updateDimensions = () => {
        setIsMobile(window.innerWidth <= 1200); // Defina o limite de largura para o modo mobile conforme necessário
        verificarNiveisLogin(dadosX)
    };

    const columns = [
        { status: 'status', label: 'Status' },
        { valor_venda: 'valor_venda', label: 'Valor venda' },
        { bandeira: 'bandeira', label: 'Bandeira' },
        { dataHora: 'dataHora', label: 'Hora' },
        { id: 'id', label: 'ID#' },
    ];

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    useEffect(() => {
        setarHora()

        getDadosSQL()
    }, []);

    const setarHora = async () => {
        localStorage.setItem('setValorFinalFloat', '');
        localStorage.setItem('setValorInicialFloat', '');

        var getDa = await getData();
        document.getElementById("horas").innerHTML = getDa;
    }

    const real = (valor) => {


        if (valor == '-') {
            return '-'
        }


        valor = valor.replace('R$', '')
        valor = parseFloat(valor)



        const options = {
            style: 'currency',
            currency: 'BRL',
            minimumFractionDigits: 2
        };



        return valor.toLocaleString('pt-BR', options);
    }

    const getData = async () => {
        var today = new Date();
        var day = today.getDate() + "";
        var month = (today.getMonth() + 1) + "";
        var year = today.getFullYear() + "";
        var hour = today.getHours() + "";
        var minutes = today.getMinutes() + "";

        day = checkZero(day);
        month = checkZero(month);
        year = checkZero(year);
        hour = checkZero(hour);
        minutes = checkZero(minutes);

        var data = day + "/" + month + "/" + year + " " + hour + ":" + minutes;

        return data;
        function checkZero(data) {
            if (data.length == 1) {
                data = "0" + data;
            }
            return data;
        }
    }

    const getDataInicial = async () => {
        var data = new Date();
        // data.setDate(data.getDate() - 1);

        var ano = data.getFullYear();
        var mes = String(data.getMonth() + 1).padStart(2, '0');
        var dia = String(data.getDate()).padStart(2, '0');

        return ano + '-' + mes + '-' + dia;
    }

    const getDataFinal = async () => {
        var data = new Date();

        var ano = data.getFullYear();
        var mes = String(data.getMonth() + 1).padStart(2, '0');
        var dia = String(data.getDate()).padStart(2, '0');

        return ano + '-' + mes + '-' + dia;
    }

    const getTokenEstabelciment = async (token) => {
        const form = new FormData();
        form.append("idempresa", "1350");
        form.append("token", "e84d7ad2-f0cc-11eb-b838-568a76eb275e");
        form.append("metodo", "ListaEstabelecimentos");


        const options = {
            method: 'POST',
        };

        options.body = form;

        var rres = await fetch('https://xuliz.com.br/painel/integracao/estabelecimento.php', options).then(response => response.json());

        var arrrayEstabelecimnto = rres.estabelecimentos;

        var tokenEstabelecimento = '';
        for (var x = 0; x < arrrayEstabelecimnto.length; x++) {
            if (arrrayEstabelecimnto[x].idestabelecimento == token) {
                tokenEstabelecimento = arrrayEstabelecimnto[x].token;
                break;
            }
        }

        //  console.log(rres)
        //  console.log(tokenEstabelecimento)
        setTokenEstabelecimento(tokenEstabelecimento)
        return tokenEstabelecimento;


    }

    const getDadosSQL = async (dataSetada, ini, fim) => {

        setRow([])
        setloading(true);
        // document.getElementById('loading').style.display = 'flex';
        // document.getElementById('exportCSV').style.display = 'none';

        await getDados()

        var dados = await localStorage.getItem('login')
        dados = JSON.parse(dados);
        var idestabele = dados.idestabelecimento;



        var getTokenEstabelecimneto = await getTokenEstabelciment(idestabele);

        if (getTokenEstabelecimneto == null) {
            getTokenEstabelecimneto = dados.tokenestabelecimento;
        }


        var datainicial = await getDataInicial()
        var datafinal = await getDataFinal()


        if (dataSetada == undefined) {

            var data = new Date();
            var data2 = new Date();

            data.setDate(data.getDate() - 7);



            setStartDate(data)
            setEndDate(data2)
        }




        if (dataSetada == true) {
            datainicial = ini;
            datafinal = fim;
        }


        const options = {
            method: 'POST',
            headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
            body: new URLSearchParams({
                idempresa: '1350',
                token: getTokenEstabelecimneto,
                idvendaxuliz: '',
                metodo: 'Lista',
                idestabelecimento: idestabele,
                idvenda: '',
                datainicial: datainicial,
                datafinal: datafinal
            })
        };

        fetch('https://xuliz.com.br/painel/integracao/vendaxuliz.php', options)
            // fetch('https://www.xulizsistemas.com.br/retaguarda/integracao/venda/listarvendageral.php', options)
            .then(response => response.json())
            .then(response => tradarDados(response, idestabele))
            .catch(err => console.error(err));

    }

    const tradarDados = async (dadosG, idestabele) => {





        if (dadosG.erro == "S") {
            alert('Encontramos um problema', dadosG.msg)
            return false;
        }

        setRow([])



        var dadosZ = dadosG.lista;
        // console.log('DADOS',dadosZ)


        var pedidos = []

        var valorVendaTotal = 0;
        var valorLiquidoX = 0;


        //FILTROS
        // var bandeira = document.getElementById('qual_bandeira').value;

        // var filtrarBandeira = false;
        // if (bandeira != '' && bandeira != 'bandeira') {
        //     filtrarBandeira = true
        // }

        // var codigoAuth = document.getElementById('codigo_auth_input').value;
        var filtrarCodigoAuth = false;

        // if (codigoAuth != '') {
        //     filtrarCodigoAuth = true;
        // }


        // var input_id = document.getElementById('input_id').value;
        // var filtrarID = false;

        // if (input_id != '') {
        //     filtrarID = true;
        // }

        var filtroVendaInicio = false;

        var valorInicio = await localStorage.getItem('setValorInicialFloat');
        if (valorInicio == null || valorInicio == '') {
            valorInicio = '0';
        }

        valorInicio = parseFloat(valorInicio);

        if (valorInicio > 0) {
            filtroVendaInicio = true;
        }


        var filtroVendaFinal = false;

        var valorFinal = await localStorage.getItem('setValorFinalFloat');
        if (valorFinal == null || valorFinal == '') {
            valorFinal = '0';
        }

        valorFinal = parseFloat(valorFinal);

        if (valorFinal > 0) {
            filtroVendaFinal = true;
        }



        // if(vendaInicio != '' && vendaInicio != '')


        var totalCREDITO = 0;
        var totalDEBITO = 0;
        var totalXuliz = 0;




        for (var x = 0; x < dadosZ.length; x++) {




            //filtros
            // if (filtrarBandeira == true) {

            //     var bandeiraG = dadosZ[x].bandeira;

            //     if (bandeiraG == null) {
            //         continue
            //     }

            //     if (!bandeiraG.includes(bandeira)) {

            //         continue
            //     }

            // }




            if (filtrarCodigoAuth == true) {
                var getCodigoAuth = '' + dadosZ[x].codigoautorizacao;

                if (!getCodigoAuth.includes(codigoAuth)) {
                    continue
                }
            }


            // if (filtrarID == true) {
            //     var id_item = '' + dadosZ[x].id;
            //     if (!id_item.includes(input_id)) {
            //         continue
            //     }
            // }


            if (filtroVendaInicio == true) {
                var totalDaVenda = '' + dadosZ[x].total;
                totalDaVenda = parseFloat(totalDaVenda);

                if (totalDaVenda < valorInicio) {
                    continue;
                }
            }


            if (filtroVendaFinal == true) {
                var totalDaVenda = '' + dadosZ[x].total;
                totalDaVenda = parseFloat(totalDaVenda);

                if (totalDaVenda > valorFinal) {
                    continue;
                }
            }


            //filtros


            var id = dadosZ[x].idvenda;
            var data = dadosZ[x].data;
            var datapagamento = '-';

            var dataHora = dadosZ[x].datestamp





            // if (dataHora != null && dataHora != '') {
            //     dataHora = dataHora.split(' ');
            //     var horas = dataHora[1].split(':')
            //     horas = horas[0] + ':' + horas[1]
            //     dataHora = dataHora[0].split('-');
            //     dataHora = dataHora[2] + '/' + dataHora[1] + '/' + dataHora[0] + "  " + horas
            //     // console.log(dataHora)
            // }

            // var valorliquido = 'R$ '+dadosZ[x].valorliquido;
            var valorliquido = 'R$ 0';


            datapagamento = dadosZ[x].datavencimento
            var meusLiquidos = 0;





            // if (dadosZ[x]) {
            //     // for(var b=0;b<meusDetalhes.length;b++){

            //     var getBruto = '' + dadosZ[x].valorbruto;
            //     var getTaxas = '' + dadosZ[x].valortaxa;

            //     getBruto = parseFloat(getBruto);


            //     // if(getBruto == 0){

            //     // getBruto = parseFloat(getBruto);
            //     //  }

            //     //taxas
            //     // var receber = ''+dadosZ[x].valorareceber;
            //     // receber = parseFloat(receber);
            //     //taxas

            //     // getTaxas = getBruto-receber;

            //     // getTaxas = parseFloat(getTaxas);

            //     // var liquido = getBruto-getTaxas

            //     // var getBandeira = '' + dadosZ[x].bandeira;


            //     // meusLiquidos = meusLiquidos+liquido;


            //     if (getBandeira.includes('Crédito')) {
            //         totalCREDITO = totalCREDITO + getBruto;
            //     }

            //     if (getBandeira.includes('Débito')) {
            //         totalDEBITO = totalDEBITO + getBruto;
            //     }

            //     if (getBandeira.includes('Xuliz')) {
            //         totalXuliz = totalXuliz + getBruto;
            //     }
            //     // }

            //     // valorliquido = 'R$ '+meusLiquidos;
            // }

            // valorliquido = dadosZ[x].detalhe[0].valorliquido

            var getBandeira = '-';

            var formaPagamento = '-';

            var filtrarBandeira = false;

            const bandeira = filters.find(filter => filter.type === 'Bandeira')?.value;

            if (bandeira != '' && bandeira != 'bandeira') {
                filtrarBandeira = true
            }

            if (dadosZ[x].transacao && dadosZ[x].transacao[0]) {
                getBandeira = dadosZ[x].transacao[0].bandeira
                formaPagamento = dadosZ[x].transacao[0].bandeira

                dadosZ[x].bandeira = getBandeira

                if (getBandeira == "MASTERCARD") {
                    formaPagamento = "Master Crédito"
                    getBandeira = "Master Crédito"
                }

                if (getBandeira == "MAESTRO") {
                    formaPagamento = "Maestro Débito"
                    getBandeira = "Master Débito"
                }

                if (getBandeira.trim() == "MAESTRO") {
                    formaPagamento = "Maestro Débito"
                    getBandeira = "Master Débito"
                }

                if (getBandeira.trim() == "MASTERCARD") {
                    formaPagamento = "Maestro Débito"
                    getBandeira = "Master Débito"
                }

                if (getBandeira == "ELO DEBITO") {
                    formaPagamento = "Elo Débito"
                    getBandeira = "Elo Débito"
                }

                if (getBandeira == "ELO CREDITO") {
                    formaPagamento = "Elo Crédito"
                    getBandeira = "Elo Crédito"
                }

                if (getBandeira == "VISA ELECTRON") {
                    formaPagamento = "Visa Débito"
                    getBandeira = "Visa Débito"
                }

                if (getBandeira.trim() == "VISA ELECTRON") {
                    formaPagamento = "Visa Débito"
                    getBandeira = "Visa Débito"
                }

                if (getBandeira == "AMEX CREDITO") {
                    formaPagamento = "Amex Crédito"
                    getBandeira = "Amex Crédito"
                }

                if (getBandeira == "VISA") {
                    formaPagamento = "Visa Crédito"
                    getBandeira = "Visa Crédito"
                }
            }

            //vendas no xuliz
            if (dadosZ[x].detalhe && dadosZ[x].detalhe[0]) {
                getBandeira = 'Xuliz'
                formaPagamento = 'Xuliz'
            }

            //filtros
            if (filtrarBandeira == true) {

                if (getBandeira == null) {
                    continue
                }

                // console.log(getBandeira + ' -- ' + bandeira)
                if (!getBandeira.includes(bandeira)) {
                    continue
                }
            }

            var valorliquido = 'R$ 0';
            valorliquido = '' + dadosZ[x].valorliquido;
            valorliquido = valorliquido.replace("-", "")
            valorliquido = parseFloat(valorliquido);



            if (data != null && data != '') {
                data = data.split('-');
                data = data[2] + '/' + data[1] + '/' + data[0]
            }


            if (datapagamento != null && datapagamento != '') {
                datapagamento = datapagamento.split('-');
                datapagamento = datapagamento[2] + '/' + datapagamento[1] + '/' + datapagamento[0]
            }

            if (datapagamento == null) {
                datapagamento = '-'
            }

            var valor_venda = 'R$ ' + dadosZ[x].valorbruto;

            var tota = dadosZ[x].valorbruto;


            if (dadosZ[x].valorbruto == null || dadosZ[x].valorbruto == '') {
                valor_venda = '-';
                tota = 0;
            }

            tota = parseFloat(tota)

            valorVendaTotal = valorVendaTotal + tota;




            var valorcashback = 'R$ ' + dadosZ[x].valorcashback;

            var valortaxa = 'R$ ' + dadosZ[x].valortaxa;



            var totaLiquido = dadosZ[x].valorliquido;

            if (dadosZ[x].valorcashback == null || dadosZ[x].valorcashback == '') {
                valorcashback = '-'
            }

            if (dadosZ[x].valortaxa == null || dadosZ[x].valortaxa == '') {
                valortaxa = '-'
            }

            if (dadosZ[x].valorliquido == null || dadosZ[x].valorliquido == '') {
                valorliquido = '-';
                totaLiquido = 0;
            }


            totaLiquido = parseFloat(totaLiquido)

            valorLiquidoX = valorLiquidoX + totaLiquido;


            var bandeiraPagamento = dadosZ[x].bandeira;

            if (bandeiraPagamento != null) {
                bandeiraPagamento = bandeiraPagamento
            }


            var forma_pagamento = dadosZ[x].bandeira

            if (forma_pagamento && forma_pagamento != "Xuliz") {
                forma_pagamento = forma_pagamento.split(" ");
                forma_pagamento = forma_pagamento[1]
            }

            var codigo_autorizacao = dadosZ[x].codigoautorizacao

            if (codigo_autorizacao == null || codigo_autorizacao == "") {
                codigo_autorizacao = "-"
            }

            var status = '' + dadosZ[x].vendastatus;


            pedidos.push(
                {
                    id: id,
                    // codigo_autorizacao: codigo_autorizacao,
                    dataHora: '' + dataHora,
                    // previsao_pagamento: '' + datapagamento,
                    bandeira: getBandeira,
                    forma_pagamento: getBandeira,
                    valor_venda: '' + valor_venda,
                    cashback: '' + valorcashback,
                    valor_taxa: '' + valortaxa,
                    valor_liquido: '' + valorliquido,
                    status: '' + status
                })
        }
        totalCREDITO = totalCREDITO.toFixed(2);
        totalCREDITO = formatCurrency(totalCREDITO)

        setGeralCredito(totalCREDITO)


        totalDEBITO = totalDEBITO.toFixed(2);
        totalDEBITO = formatCurrency(totalDEBITO)

        setGeralDebito(totalDEBITO)


        totalXuliz = totalXuliz.toFixed(2);
        totalXuliz = formatCurrency(totalXuliz)


        setGeralXuliz(totalXuliz)

        setQuantidadeVendas(pedidos.length)
        setValorTotal('R$ ' + valorVendaTotal)
        setValorLiquido('R$ ' + valorLiquidoX)

        setloading(false);
        // document.getElementById('loading').style.display = 'none';
        // document.getElementById('exportCSV').style.display = 'flex';


        pedidos = pedidos.sort((a, b) => b.id - a.id);
        // console.log('dadosZ', dadosZ)



        setRow(pedidos)

    }

    function sleep(ms) {
        return new Promise(resolve => setTimeout(resolve, ms));
    }

    const verDetalhes = async (dados) => {
        // alert(JSON.stringify(dados))

        var data = dados.dataHora;

        data = data.split(" ")
        data = data[0]



        location.href = './detalhesvendas?id=' + dados.id + '&data=' + data
        // console.log(data_hora)
    }

    const getDados = async () => {
        var dados = await localStorage.getItem('login')

        if (dados == null) {
            navigate("/");

            return false;
        }
        dados = JSON.parse(dados)
        setDadosX(dados)
        verificarNiveisLogin(dados)


        setEmailRef(dados.email)


    }

    const verificarNiveisLogin = async (dadosX) => {
        var idperfil = dadosX.idusuariotipo;



        if (idperfil == "7") {
            await sleep(50)

            //financeiro somente relatorios
            document.getElementById('realizar_vendaID').style.display = "none";
            document.getElementById('menu_marketing').style.display = "none";
            document.getElementById('menu_configuracoes').style.display = "none";

        }

        if (idperfil == "8") {
            await sleep(50)

            location.href = "./xloja";

        }

    }

    function sleep(ms) {
        return new Promise(resolve => setTimeout(resolve, ms));
    }

    const formatCurrency = (value) => {
        return new Intl.NumberFormat('pt-BR', {
            style: 'currency',
            currency: 'BRL'
        }).format(parseFloat(value.replace(/[^\d.-]/g, '')));
    };


    return (
        <Grid
            sx={{
                paddingTop: isMobile ? null : '34px',
                padding: isMobile ? '34px 20px 34px 20px' : null
            }}>

            <Typography sx={{ fontSize: '32px', color: '#142A30', fontWeight: 700, }}>
                Resumo vendas hoje
            </Typography>

            <Paper xs={12} elevation={0} sx={{ width: '100%', overflow: 'hidden', marginTop: '14px' }} >
                <TableContainer xs={6} sx={{
                    minWidth: '1040px',
                    overflowY: 'auto',
                    direction: 'rtl', // Mantém a barra de rolagem à esquerda
                    '&::-webkit-scrollbar': {
                        width: '6px', // Largura da barra de rolagem
                        height: '6px', // Largura da barra de rolagem
                    },
                    '&::-webkit-scrollbar-thumb': {
                        backgroundColor: '#5A8292', // Cor da barra
                        borderRadius: '10px', // Borda arredondada
                        border: '1px solid trasparent', // Espaço para adicionar efeito de "padding"
                        backgroundClip: 'content-box', // Para manter o border transparente
                    },
                    '&::-webkit-scrollbar-track': {
                        backgroundColor: '#F0EFEB', // Cor do track (fundo da barra)
                        borderRadius: '10px',
                    },
                    '&::-webkit-scrollbar-thumb:hover': {
                        backgroundColor: '#555', // Cor ao passar o mouse sobre a barra
                    },
                }}>
                    <Table stickyHeader aria-label="sticky table">
                        <TableHead>
                            <TableRow>
                                {columns.map((column) => (
                                    <TableCell
                                        sx={{ fontSize: '15px', color: '#142A3099' }}
                                        key={column.id}
                                        align={column.align}
                                    >
                                        {column.label}
                                    </TableCell>
                                ))}
                            </TableRow>

                        </TableHead>
                        <TableBody>
                            {rows.map((row) => {
                                return (
                                    <TableRow key={row.id}>
                                        <TableCell component="th" scope="row">
                                            <Grid sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'end' }}>
                                                {/* <Typography sx={styles.actionTable} onClick={() => { verDetalhes(row) }}>⋮</Typography> */}
                                                <Typography sx={styles.statusTable}>{row.status}</Typography>
                                            </Grid>
                                        </TableCell>
                                        <TableCell component="th" sx={styles.textTable}>
                                            {formatCurrency(row.valor_liquido)}
                                        </TableCell>
                                        <TableCell component="th" scope="row">
                                            {row.bandeira ? (
                                                <>
                                                    {row.bandeira === 'XULIZ' || row.bandeira === 'Xuliz' ? (
                                                        <img src={flagXulis} style={{ width: 50, borderRadius: 4 }} alt="Bandeira XULIZ" />
                                                    ) : row.bandeira === 'Visa Crédito' || row.bandeira === "VISA" || row.bandeira === "VISA ELECTRON" || row.bandeira === 'Visa Débito' ? (
                                                        <img src={visa} style={{ width: 50, borderRadius: 4 }} alt="Visa" />
                                                    ) : row.bandeira === 'Master Crédito' || row.bandeira === "MASTERCARD" || row.bandeira === "MAESTRO" || row.bandeira === 'Master Débito' ? (
                                                        <img src={master} style={{ width: 50, borderRadius: 4 }} alt="MasterCard" />
                                                    ) : row.bandeira === 'Elo Crédito' || row.bandeira === 'ELO DEBITO' || row.bandeira === 'Elo Débito' ? (
                                                        <img src={elo} style={{ width: 50, borderRadius: 4 }} alt="Elo" />
                                                    ) : row.bandeira === 'Amex Crédito' || row.bandeira === 'Amex Débito' ? (
                                                        <img src={amex} style={{ width: 50, borderRadius: 4 }} alt="American Express" />
                                                    ) : row.bandeira === 'Hipercard Crédito' || row.bandeira === 'Hipercard Débito' ? (
                                                        <img src={hipercard} style={{ width: 50, borderRadius: 4 }} alt="Hipercard" />
                                                    ) : row.bandeira === '-' ? (
                                                        <>
                                                            <img src={Pix} style={{ width: 50, borderRadius: 4 }} alt="Pix" />
                                                        </>
                                                    ) : (
                                                        <p style={{ marginBottom: 0 }}>{row.bandeira}</p>
                                                    )}
                                                </>
                                            ) : null}
                                        </TableCell>
                                        <TableCell component="th" scope="row" sx={styles.textTable}>
                                            {format(row.dataHora, "HH:mm")}
                                        </TableCell>
                                        <TableCell component="th" scope="row" sx={styles.textTable}>
                                            {row.id}
                                        </TableCell>
                                    </TableRow>
                                );
                            })}
                        </TableBody>
                    </Table>

                    {loading ?
                        <div style={{ width: '100%', display: 'flex', justifyContent: 'center', paddingTop: 30 }} id="loading">
                            <img src={waiting} style={{ width: 50, height: 50 }} />
                        </div> : ''}

                </TableContainer>
                {/* <TablePagination
                    rowsPerPageOptions={[10, 25, 100]}
                    component="div"
                    count={rows.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                /> */}
            </Paper>
        </Grid >
    )
};

export default ResumoVendasHoje;

const styles = Stylesheet.create({
    textTable: {
        color: '#142A30',
        fontSize: '15px',
        fontWeight: 700,
        textAlign: 'end',
        marginBottom: 0,
    },
    taxaTable: {
        color: '#E95454',
        fontSize: '15px',
        fontWeight: 700,
        textAlign: 'end',
        marginBottom: 0,
    },
    statusTable: {
        color: '#7FA800',
        fontSize: '15px',
        fontWeight: 700,
        textAlign: 'end',
        marginBottom: 0,
    },
    actionTable: {
        cursor: 'pointer',
        color: '#5A8292',
        fontSize: '15px',
        fontWeight: 700,
        marginBottom: 0,
        marginLeft: '48px',
        fontSize: 17,
        fontWeight: 'bold'
    }
})