function reduceName(texto) {
    if (!texto) {
        return "";
    }

    const palavrasExcecao = ['de', 'a', 'o', 'para', 'em', 'com', 'do', 'da', 'dos', 'das', 'se', 'um', 'uma'];
    const palavras = texto.split(' ');
    let resultado = [];

    for (let i = 0; i < palavras.length; i++) {
        const palavra = palavras[i];
        resultado.push(palavra.charAt(0).toUpperCase() + palavra.slice(1).toLowerCase());

        // Se a palavra atual nãso é uma exceção e já capturamos o segundo nome, paramos o loop
        if (!palavrasExcecao.includes(palavra.toLowerCase()) && resultado.length > 1) {
            break;
        }
    }

    return resultado.join(' ');
};

module.exports = reduceName;