import { Button, Grid, OutlinedInput, Typography } from "@mui/material";
import Stylesheet from "reactjs-stylesheet";
import xulisGirl from "../assets/imgs/xulisGirl.png";
import Lista from "./Lista";



const Content = ({ screenWidth, allMembros }) => {

    function calcMimMax(min, max, divide) {
        const calculated = screenWidth / divide;
        s
        if (calculated > min && calculated < max) {
            return calculated;
        } else if (calculated < min) {
            return min;
        } else if (calculated > max) {
            return max;
        }

        return 16;
    }

    return (
        <Grid sx={{ width: '100%' }}>

            <Grid sx={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}>
                <Grid sx={{
                    width: '70%',
                    display: 'flex',
                    flexWrap: 'wrap',
                    bgcolor: '#F0EFEB',
                    justifyContent: 'space-between',
                    padding: '25px',
                    borderRadius: '20px',
                    gap: '10px'
                }}>
                    <OutlinedInput
                        sx={{
                            ...styles.customInputNumber,
                            '& .MuiOutlinedInput-input': {
                                display: 'flex',
                                justifyContent: 'center',
                                borderRadius: '20px',
                                border: '2px solid #142A3066'
                            },
                            '& .MuiInputBase-input': {
                                color: '#142A30',
                                fontWeight: 'bold',
                                fontSize: calcMimMax(12, 16, 100),
                                padding: '12px',
                            },
                            '& .MuiInputBase-input::placeholder': {
                                fontSize: calcMimMax(12, 16, 100),
                                color: '#142A3066',
                                opacity: 1,
                                fontWeight: 'bold',
                            },
                            '& .MuiOutlinedInput-notchedOutline': {
                                border: 'none',
                            },
                        }}
                        type="text"
                        placeholder="Número de celular"
                    // value={dadosClinte?.nome ? dadosClinte.nome : ''}
                    />
                    <OutlinedInput
                        sx={{
                            ...styles.customInputNumber,
                            '& .MuiOutlinedInput-input': {
                                display: 'flex',
                                justifyContent: 'center',
                                borderRadius: '20px',
                                border: '2px solid #142A3066'
                            },
                            '& .MuiInputBase-input': {
                                color: '#142A30',
                                fontWeight: 'bold',
                                fontSize: calcMimMax(12, 16, 100),
                                padding: '12px',
                            },
                            '& .MuiInputBase-input::placeholder': {
                                fontSize: calcMimMax(12, 16, 100),
                                color: '#142A3066',
                                opacity: 1,
                                fontWeight: 'bold',
                            },
                            '& .MuiOutlinedInput-notchedOutline': {
                                border: 'none',
                            },
                        }}
                        type="text"
                        placeholder="Relação com empresa"
                    // value={dadosClinte?.nome ? dadosClinte.nome : ''}
                    />
                    <OutlinedInput
                        sx={{
                            ...styles.customInputNumber,
                            '& .MuiOutlinedInput-input': {
                                display: 'flex',
                                justifyContent: 'center',
                                borderRadius: '20px',
                                border: '2px solid #142A3066'
                            },
                            '& .MuiInputBase-input': {
                                color: '#142A30',
                                fontWeight: 'bold',
                                fontSize: calcMimMax(12, 16, 100),
                                padding: '12px',
                            },
                            '& .MuiInputBase-input::placeholder': {
                                fontSize: calcMimMax(12, 16, 100),
                                color: '#142A3066',
                                opacity: 1,
                                fontWeight: 'bold',
                            },
                            '& .MuiOutlinedInput-notchedOutline': {
                                border: 'none',
                            },
                        }}
                        type="text"
                        placeholder="Nome"
                    // value={dadosClinte?.nome ? dadosClinte.nome : ''}
                    />
                </Grid>

                <Grid sx={{
                    width: '29%',
                    display: 'flex',
                    flexDirection: 'row',
                    bgcolor: '#F0EFEB',
                    justifyContent: 'space-around',
                    padding: '25px',
                    borderRadius: '20px',
                    gap: '10px'
                }}>
                    <img src={xulisGirl} style={{ objectFit: 'cover', width: '116px', height: '116px', borderTopRightRadius: '20px' }} />
                    <Grid>
                        <Grid>
                            <Typography sx={{ fontSize: '15px', color: '#142A3099' }}>Nome</Typography>
                            <Typography sx={{ fontSize: '16px', color: '#142A30', fontWeight: 700 }}>Rubens Schueng Netto</Typography>
                        </Grid>
                        <Grid>
                            <Typography sx={{ fontSize: '15px', color: '#142A3099' }}>Situação</Typography>
                            <Typography sx={{ fontSize: '16px', color: '#142A30', fontWeight: 700 }}>OK</Typography>
                        </Grid>
                    </Grid>

                </Grid>
            </Grid>

            <Grid sx={{ border: '1px solid #142A3099', margin: '30px 0px' }} />

            <Grid sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
            }}>
                <Grid sx={{ width: '32%' }}>
                    <Grid sx={{ paddingBottom: '44px' }}>
                        <Typography sx={{ fontSize: '24px', color: '#142A30', fontWeight: 700 }}>Adicionar Submembros+</Typography>
                        <Typography sx={{ fontSize: '15px', color: '#142A3099' }}>Área destinada ao cadastro de submembros+ do membro+ acima.</Typography>
                    </Grid>

                    <Grid sx={{ display: 'flex', flexDirection: 'row', alignitems: 'center', justifyContent: 'space-between', paddingBottom: '25px' }}>

                        <Grid sx={{ display: 'flex', flexDirection: 'row' }}>
                            <img src={xulisGirl} style={{ objectFit: 'cover', width: '50px', height: '50px', borderRadius: '20px', marginRight: '16px' }} />
                            <Grid>
                                <Typography sx={{ fontSize: '12px', color: '#142A3099' }}>Situação</Typography>
                                <Typography sx={{ fontSize: '16px', color: '#142A30', fontWeight: 700 }}>DIGITE O NÚMERO</Typography>
                            </Grid>
                        </Grid>

                        <Grid sx={{ height: '50px', border: '1px solid #142A3099' }} />

                        <Grid>
                            <Typography sx={{ width: '20ch', fontSize: '15px', color: '#142A3099', lineHeight: '17.6px' }}>
                                *Usuário não existente, vai ser enviado um SMS para que seja criada a conta.
                            </Typography>
                        </Grid>
                    </Grid>

                    <Grid>
                        <OutlinedInput
                            sx={{
                                width: '100%',
                                bgcolor: '#FEFDF9',
                                paddingBottom: '25px',
                                '& .MuiOutlinedInput-input': {
                                    display: 'flex',
                                    justifyContent: 'center',
                                    borderRadius: '20px',
                                    border: '2px solid #142A3066'
                                },
                                '& .MuiInputBase-input': {
                                    color: '#142A30',
                                    fontWeight: 'bold',
                                    fontSize: calcMimMax(12, 16, 100),
                                    padding: '12px',
                                },
                                '& .MuiInputBase-input::placeholder': {
                                    fontSize: calcMimMax(12, 16, 100),
                                    color: '#142A3066',
                                    opacity: 1,
                                    fontWeight: 'bold',
                                },
                                '& .MuiOutlinedInput-notchedOutline': {
                                    border: 'none',
                                },
                            }}
                            type="text"
                            placeholder="Número de celular"
                        // value={dadosClinte?.nome ? dadosClinte.nome : ''}
                        />

                        <OutlinedInput
                            sx={{
                                width: '100%',
                                bgcolor: '#FEFDF9',
                                paddingBottom: '25px',
                                '& .MuiOutlinedInput-input': {
                                    display: 'flex',
                                    justifyContent: 'center',
                                    borderRadius: '20px',
                                    border: '2px solid #142A3066'
                                },
                                '& .MuiInputBase-input': {
                                    color: '#142A30',
                                    fontWeight: 'bold',
                                    fontSize: calcMimMax(12, 16, 100),
                                    padding: '12px',
                                },
                                '& .MuiInputBase-input::placeholder': {
                                    fontSize: calcMimMax(12, 16, 100),
                                    color: '#142A3066',
                                    opacity: 1,
                                    fontWeight: 'bold',
                                },
                                '& .MuiOutlinedInput-notchedOutline': {
                                    border: 'none',
                                },
                            }}
                            type="text"
                            placeholder="Nome"
                        // value={dadosClinte?.nome ? dadosClinte.nome : ''}
                        />
                        <OutlinedInput
                            sx={{
                                width: '100%',
                                bgcolor: '#FEFDF9',
                                paddingBottom: '25px',
                                '& .MuiOutlinedInput-input': {
                                    display: 'flex',
                                    justifyContent: 'center',
                                    borderRadius: '20px',
                                    border: '2px solid #142A3066'
                                },
                                '& .MuiInputBase-input': {
                                    color: '#142A30',
                                    fontWeight: 'bold',
                                    fontSize: calcMimMax(12, 16, 100),
                                    padding: '12px',
                                },
                                '& .MuiInputBase-input::placeholder': {
                                    fontSize: calcMimMax(12, 16, 100),
                                    color: '#142A3066',
                                    opacity: 1,
                                    fontWeight: 'bold',
                                },
                                '& .MuiOutlinedInput-notchedOutline': {
                                    border: 'none',
                                },
                            }}
                            type="text"
                            placeholder="Relação com membro+"
                        // value={dadosClinte?.nome ? dadosClinte.nome : ''}
                        />

                        <Button sx={{
                            bgcolor: '#17333A',
                            padding: '10px 15px',
                            borderRadius: '20px',
                            '&:hover': {
                                backgroundColor: '#17333A'
                            },
                        }}
                            onClick={() => console.log('chamou alterarCash')}>
                            {/* onClick={() => alterarCash()}> */}
                            <Typography sx={{ color: '#FFFFFF', textTransform: 'none', fontSize: '15px' }}>
                                Adicionar Submembro+
                            </Typography>
                        </Button>
                    </Grid>


                </Grid>

                <Grid sx={{ width: '65%' }}>
                    <Grid sx={{ paddingBottom: '44px' }}>
                        <Typography sx={{ fontSize: '24px', color: '#142A30', fontWeight: 700 }}>Submembros+ cadastrados</Typography>
                        <Typography sx={{ fontSize: '15px', color: '#142A3099' }}>Abaixo estão todos os submembros+ que foram cadastrados.</Typography>
                    </Grid>

                    <Lista allMembros={allMembros} />

                </Grid>

            </Grid>

            <Grid sx={{ border: '1px solid #142A3099', margin: '30px 0px' }} />

            <Grid sx={{
                display: 'flex',
                justifyContent: 'flex-end',
                gap: '15px',
                // paddingRight: '25px',
            }}>
                <Button sx={{
                    bgcolor: '#E57373',
                    padding: '10px 15px',
                    borderRadius: '20px',
                    '&:hover': {
                        backgroundColor: '#17333A'
                    },
                }}
                    onClick={() => console.log('chamou1')}>
                    <Typography sx={{ color: '#FFFFFF', textTransform: 'none', fontSize: '15px' }}>
                        Cancelar
                    </Typography>
                </Button>
                <Button sx={{
                    bgcolor: '#17333A',
                    padding: '10px 15px',
                    borderRadius: '20px',
                    '&:hover': {
                        backgroundColor: '#17333A'
                    },
                }}
                    onClick={() => console.log('chamou2')}>
                    <Typography sx={{ color: '#FFFFFF', textTransform: 'none', fontSize: '15px' }}>
                        Salvar Membro+
                    </Typography>
                </Button>
            </Grid>

        </Grid >
    );
};

export default Content;

const styles = Stylesheet.create({
    customInputNumber: {

        flexGrow: 1,
        flexShrink: 1,
        flexBasis: '49%',
        minWidth: '295px',
        maxWidth: '100%',
        // width: '49%',
        bgcolor: '#FEFDF9',
        borderRadius: '15px',
        // '& .MuiOutlinedInput-notchedOutline': {
        //     border: '2px solid #142A3066',
        // },
        '& input[type=number]::-webkit-outer-spin-button, & input[type=number]::-webkit-inner-spin-button': {
            WebkitAppearance: 'none',
            margin: 0,
        },
    }
})


{/* <OutlinedInput
sx={{
    ...styles.customInputNumber,
    '& .MuiOutlinedInput-input': {
        display: 'flex',
        justifyContent: 'center',
        borderRadius: '20px',
        border: '2px solid #142A3066'
    },
    '& .MuiInputBase-input': {
        color: '#142A30',
        fontWeight: 'bold',
        fontSize: calcMimMax(12, 16, 100),
        padding: '12px',
    },
    '& .MuiInputBase-input::placeholder': {
        fontSize: calcMimMax(12, 16, 100),
        color: '#142A3066',
        opacity: 1,
        fontWeight: 'bold',
    },
    '& .MuiOutlinedInput-notchedOutline': {
        border: 'none',
    },
}}
type="text"
placeholder="Nome"
// value={dadosClinte?.nome ? dadosClinte.nome : ''}
/> */}