import Stylesheet from "reactjs-stylesheet";
import CustomSeparator from "../componentes/Breadcrumb";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faChevronRight } from "@fortawesome/free-solid-svg-icons";
import { Button, Grid, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material";

import visa from '../assets/visa.png';
import master from '../assets/master.png';
import elo from '../assets/elo_debito.png';
import hipercard from '../assets/hipercard.png';
import amex from '../assets/amex.png';
import Pix from '../assets/Pix.png';
import flagXulis from '../assets/flags/flagXulis.png';
import { format } from "date-fns";

const DetalhesVendas = ({
    isMobile,
    screenWidth,

    totalVenda,
    totalTarifa,
    valorCashback,
    valorLiquido,
    codigoAutorizacao,
    dataTransacao,
    horaTransacao,
    vendastatus,

    id,
    dataHora,
    codigo_autorizacao,
    bandeira,
    forma_pagamento,
    valor_venda,
    cashback,
    valor_taxa,
    valor_liquido,
    valor_liquido_total,
    parcelamentosData
}) => {

    const columns = [
        { valor_liquido: 'valor_liquido', label: 'Valor líquido' },
        { valor_taxa: 'valor_taxa', label: 'Valor da taxa/tarifa' },
        { cashback: 'Cashback', label: 'Cashback' },
        { valor_venda: 'valor_venda', label: 'Valor venda' },
        { forma_pagamento: 'forma_pagamento', label: 'Forma de Pagamento' },
        { bandeira: 'bandeira', label: 'Bandeira' },
        { dataHora: 'dataHora', label: 'Data/Hora' },
        { codigo_autorizacao: 'codigo_autorizacao', label: 'Código de Autorização' },
        { id: 'id', label: 'ID#' },
    ];

    const rows = [{
        id: id,
        dataHora: dataHora,
        codigo_autorizacao: codigo_autorizacao,
        bandeira: bandeira,
        forma_pagamento: forma_pagamento,
        valor_venda: valor_venda,
        cashback: cashback,
        valor_taxa: valor_taxa,
        valor_liquido: valor_liquido,
        valor_liquido_total: valor_liquido_total
    }];

    console.log('rows', rows)

    console.log('parcelamentosData', parcelamentosData)

    const otherData = [
        { label: 'Código de venda', value: parcelamentosData[0]?.idvenda },
        // { label: '#ID Venda', value: parcelamentosData[0]?.codigoautorizacao },
        { label: 'Número do cartão', value: parcelamentosData[0]?.numerocartao == "" ? "Sem numero " : parcelamentosData[0]?.bandeira == "Xuliz" ? `**** **** **** ${parcelamentosData[0]?.numerocartao}` : parcelamentosData[0]?.numerocartao },
        // { label: 'Número da maquininha', value: 'Não se aplica' },
        { label: 'Taxas', value: `${((parseFloat(valor_taxa) / parseFloat(valor_venda)) * 100).toFixed(1)}%` },
        { label: 'Canal de venda', value: parcelamentosData[0]?.online == 'S' ? 'Aplicativo' : 'Maquininha' },
    ]

    const plotColumns = [
        { key: 'idvenda', label: 'ID#' },
        { key: 'datavencimento', label: 'Data de Vencimento' },
        { key: 'valorbruto', label: 'Valor Bruto' },
        { key: 'valorliquido', label: 'Valor Líquido' },
        { key: 'status', label: 'Status' },
    ];

    const bigValues = [
        { label: 'Valor da venda', value: totalVenda, color: '#142A30' },
        { label: 'Valor taxa/tarifa', value: totalTarifa, color: '#E57373' },
        { label: 'Valor cashback', value: valorCashback, color: '#7FA800' },
        // { label: 'Valor líquido da venda', value: valorLiquido, color: '#142A30' }
    ]

    const formatCurrency = (inputValue) => {
        const rawValue = inputValue.replace(/\D/g, '');

        const formatter = new Intl.NumberFormat('pt-BR', {
            style: 'currency',
            currency: 'BRL',
        });

        return formatter.format(rawValue / 100);
    };

    const statusImagemAprovacao = (data, as) => {
        var getData = data.split("/");

        var today = new Date();

        var mes = '' + getData[1];
        mes = mes - 1;

        var dataComparar = new Date(getData[2], mes, getData[0]);

        if (dataComparar > today) {
            return 0
        }

        return 1
    }

    function calcMimMax(min, max, divide) {
        const calculated = screenWidth / divide;

        if (calculated > min && calculated < max) {
            return calculated;
        } else if (calculated < min) {
            return min;
        } else if (calculated > max) {
            return max;
        }

        return 16;
    }

    return (
        <Grid item xs sx={screenWidth <= 1400 ? styles.contentSmallScreen : isMobile ? styles.contentMobile : styles.content}>
            {isMobile ? null :
                <Grid sx={[screenWidth <= 1400 ? styles.customSeparatorSmallScreen : styles.customSeparator, { flexDirection: screenWidth <= 800 ? 'column' : 'row' }]}>
                    <CustomSeparator menuOption={'Xloja'} item={2} />
                    <div style={{ height: 50, display: "flex", width: "30%", alignItems: 'center', justifyContent: 'flex-end' }}>
                        <span style={{ marginLeft: 10, fontSize: 20, marginLeft: 30, fontWeight: "bold" }} id="horas"></span>
                    </div>
                </Grid>
            }
            <Grid container spacing={0} style={{ width: '100%', display: "flex", flexDirection: 'column', justifyContent: 'space-between' }}>

                <Grid sx={{ paddingBottom: '25px' }}>
                    <Button onClick={() => history.back()} sx={{ paddingBottom: '25px' }}>
                        <FontAwesomeIcon
                            icon={faArrowLeft}
                            style={{ fontSize: '16px', color: "#999999", cursor: 'pointer', paddingRight: '3px' }}
                        />
                        <Typography style={{ fontSize: '13px', color: '#999999', textTransform: 'none' }}>
                            Voltar
                        </Typography>
                    </Button>

                    <Grid sx={{
                        display: 'flex',
                        alignItems: 'self-start',
                        flexDirection: 'column'
                    }}>
                        <Typography style={{ fontSize: '24px', color: '#142A30', fontWeight: 700, paddingBottom: '15px' }}>
                            Detalhe da venda
                        </Typography>
                        <Grid sx={{
                            display: 'flex',
                            alignItems: 'center',
                            flexDirection: 'row',
                            gap: '5px',
                            backgroundColor: '#142A3066',
                            padding: '5px',
                            borderRadius: '5px'
                        }}>
                            <Typography style={{ fontSize: '11px', color: '#142A30', fontWeight: 700 }}>
                                Histórico de vendas
                            </Typography>
                            <FontAwesomeIcon
                                icon={faChevronRight}
                                style={{ fontSize: '10px', color: "#142A30", cursor: 'pointer', paddingRight: '3px' }}
                            />
                            <Typography style={{ fontSize: '11px', color: '#142A30' }}>
                                Detalhe da venda #165
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>

                <Grid sx={{
                    width: '100%',
                    display: 'flex',
                    paddingBottom: '34px',
                    justifyContent: 'space-between',
                    flexDirection: screenWidth <= 1670 ? 'column' : 'row'
                }}>

                    <Grid sx={{
                        width: '100%',
                        paddingTop: '20px',
                        display: screenWidth <= 1670 ? 'flex' : null,
                        justifyContent: 'space-between',
                        flexDirection: isMobile ? 'column' : screenWidth <= 1670 ? 'row' : null
                    }}>

                        <Grid sx={{
                            gap: isMobile ? null : '4%',
                            display: 'flex',
                            paddingBottom: '35px',
                            flexWrap: screenWidth <= 1165 ? 'wrap' : 'nowrap',
                            width: isMobile ? null : screenWidth <= 1670 ? '70%' : null,
                            justifyContent: screenWidth <= 1670 ? 'space-between' : null,
                        }}>
                            {bigValues.map((i) => (
                                <Grid sx={{
                                    width: isMobile ? null : `${calcMimMax((20 + i.value.length), (25 + i.value.length), 41)}%`,
                                }}>
                                    <Typography style={{ ...styles.titleBigPrice, fontSize: calcMimMax(14, 15, 74) }}>
                                        {i.label}
                                    </Typography>
                                    <Typography style={{
                                        ...styles.valueBigPrices,
                                        fontSize: calcMimMax(isMobile ? 24 : screenWidth <= 900 ? 22 : 24, 32, 43),
                                        color: `${i.color}`
                                    }}>
                                        R$ {i.value == 'NaN' ? '0,00' : i.value}
                                    </Typography>
                                </Grid>
                            ))}
                        </Grid>

                        <Grid sx={{ width: isMobile ? null : screenWidth <= 1024 ? '30%' : null }}>
                            <Typography style={styles.titleBigPrice}>
                                Valor líquido da venda
                            </Typography>
                            <Typography style={{ fontSize: calcMimMax(screenWidth <= 900 ? 35 : 40, 48, 41), color: '#142A30', fontWeight: 700 }}>
                                R$ {valor_liquido_total != "0" ? valor_liquido_total : valorLiquido}
                            </Typography>
                        </Grid>

                    </Grid>

                    <Grid sx={{
                        gap: screenWidth <= 950 ? '20px' : '65px',
                        paddingY: screenWidth <= 950 ? '15px' : null,
                        width: '100%',
                        display: 'flex',
                        borderRadius: '20px',
                        alignItems: 'center',
                        paddingInline: '50px',
                        border: '2px solid #9999994D',
                        justifyContent: isMobile ? 'flex-start' : screenWidth <= 1670 ? 'space-evenly' : null,
                        flexWrap: screenWidth <= 950 ? 'wrnowrapap' : 'nowrap'
                    }}>

                        <Grid sx={{
                            width: '100%',
                            display: 'flex',
                            justifyContent: 'space-evenly',
                            gap: screenWidth <= 900 ? '20px' : '40px',
                            flexWrap: screenWidth <= 900 ? 'wrap' : 'nowrap',

                        }}>
                            <Grid>
                                <Typography style={{ fontSize: '15px', color: '#999999', fontWeight: 400 }}>
                                    Cód. Autorização
                                </Typography>
                                <Typography style={{ fontSize: '24px', fontWeight: 600, color: '#142A30' }}>
                                    {codigoAutorizacao}
                                </Typography>
                            </Grid>
                            <Grid>
                                <Typography style={{ fontSize: '15px', color: '#999999', fontWeight: 400, paddingBottom: '6px' }}>
                                    Realizado em
                                </Typography>
                                <Grid>
                                    <Typography style={{ fontSize: '24px', fontWeight: 600, color: '#142A30', lineHeight: 1 }}>
                                        {dataTransacao}
                                    </Typography>
                                    <Typography style={{ fontSize: '15px', fontWeight: 600, color: '#142A30', marginTop: '-4px' }}>
                                        às {horaTransacao}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>

                        {isMobile ? null : <Grid sx={{ margin: '20px 0px', height: '80px', borderRight: '2px solid #9999994D' }} />}

                        <Grid>
                            <Typography style={{ fontSize: '15px', color: '#999999', fontWeight: 400 }}>
                                Status da venda
                            </Typography>
                            <Typography style={{ fontSize: '24px', fontWeight: 700, color: vendastatus == 'Concluída' ? '#7FA800' : '#E17E36' }}>
                                {vendastatus == 'Concluída' ? 'Aprovado' : 'Agendado'}
                            </Typography>
                        </Grid>

                    </Grid>

                </Grid>

                <Paper xs={12} elevation={0} sx={{ width: '100%', overflow: 'hidden' }}>
                    <TableContainer xs={6} sx={{
                        maxHeight: 720,
                        overflowY: 'auto',
                        direction: 'rtl', // Mantém a barra de rolagem à esquerda
                        '&::-webkit-scrollbar': {
                            width: '6px', // Largura da barra de rolagem
                            height: '6px', // Largura da barra de rolagem
                        },
                        '&::-webkit-scrollbar-thumb': {
                            backgroundColor: '#5A8292', // Cor da barra
                            borderRadius: '10px', // Borda arredondada
                            border: '1px solid trasparent', // Espaço para adicionar efeito de "padding"
                            backgroundClip: 'content-box', // Para manter o border transparente
                        },
                        '&::-webkit-scrollbar-track': {
                            backgroundColor: '#F0EFEB', // Cor do track (fundo da barra)
                            borderRadius: '10px',
                        },
                        '&::-webkit-scrollbar-thumb:hover': {
                            backgroundColor: '#555', // Cor ao passar o mouse sobre a barra
                        },
                    }}>

                        <Table stickyHeader aria-label="sticky table">
                            <TableHead>
                                <TableRow>
                                    {columns.map((column) => (
                                        <TableCell
                                            sx={{ fontSize: '15px', color: '#142A3099' }}
                                            key={column.id}
                                            align={column.align}
                                        >
                                            {column.label}
                                        </TableCell>
                                    ))}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {rows.map((row) => {
                                    return (
                                        <TableRow key={row.id}>
                                            <TableCell component="th" sx={styles.textTable}>
                                                {formatCurrency(row.valor_liquido_total != "0" ? row.valor_liquido_total : row.valor_liquido)}
                                            </TableCell>
                                            <TableCell component="th" scope="row" sx={styles.taxaTable}>
                                                {formatCurrency(row.valor_taxa)} -
                                            </TableCell>
                                            <TableCell component="th" sx={styles.cashbackTable}>
                                                {formatCurrency(row.cashback)}
                                            </TableCell>
                                            <TableCell component="th" sx={styles.textTable}>
                                                {formatCurrency(row.valor_venda)}
                                            </TableCell>
                                            <TableCell component="th" sx={styles.textTable}>
                                                {row.forma_pagamento == "Xuliz" ? "Xulis" : row.forma_pagamento} {row.vendaparcela > 1 ? ` - ${row.vendaparcela}x` : ''}
                                            </TableCell>
                                            <TableCell component="th" scope="row">
                                                {row.bandeira ? (
                                                    <>
                                                        {row.bandeira === 'XULIZ' || row.bandeira === 'Xuliz' ? (
                                                            <img src={flagXulis} style={{ width: 50, borderRadius: 4 }} alt="Bandeira XULIZ" />
                                                        ) : row.bandeira === 'Visa Crédito' || row.bandeira === "VISA" || row.bandeira === "VISA ELECTRON" || row.bandeira === 'Visa Débito' ? (
                                                            <img src={visa} style={{ width: 50, borderRadius: 4 }} alt="Visa" />
                                                        ) : row.bandeira === 'Master Crédito' || row.bandeira === "MASTERCARD" || row.bandeira === "MAESTRO" || row.bandeira === 'Master Débito' ? (
                                                            <img src={master} style={{ width: 50, borderRadius: 4 }} alt="MasterCard" />
                                                        ) : row.bandeira === 'Elo Crédito' || row.bandeira === 'ELO DEBITO' || row.bandeira === 'Elo Débito' ? (
                                                            <img src={elo} style={{ width: 50, borderRadius: 4 }} alt="Elo" />
                                                        ) : row.bandeira === 'Amex Crédito' || row.bandeira === 'Amex Débito' ? (
                                                            <img src={amex} style={{ width: 50, borderRadius: 4 }} alt="American Express" />
                                                        ) : row.bandeira === 'Hipercard Crédito' || row.bandeira === 'Hipercard Débito' ? (
                                                            <img src={hipercard} style={{ width: 50, borderRadius: 4 }} alt="Hipercard" />
                                                        ) : row.bandeira === 'PIX' ? (
                                                            <>
                                                                <img src={Pix} style={{ width: 50, borderRadius: 4 }} alt="Pix" />
                                                            </>
                                                        ) : (
                                                            <p style={{ marginBottom: 0 }}>{row.bandeira}</p>
                                                        )}
                                                    </>
                                                ) : null}
                                            </TableCell>
                                            <TableCell component="th" scope="row" sx={{ ...styles.textTable, width: '16ch' }}>
                                                {row.dataHora}
                                            </TableCell>
                                            <TableCell component="th" scope="row" sx={styles.textTable}>
                                                {row.codigo_autorizacao}
                                            </TableCell>
                                            <TableCell component="th" scope="row" sx={styles.textTable}>
                                                {row.id}
                                            </TableCell>
                                        </TableRow>
                                    );
                                })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Paper>

                <Paper sx={{
                    width: '100%',
                    gap: '60px',
                    paddingBottom: '25px',
                    backgroundColor: '#F1F1F1',
                    display: 'flex',
                    padding: '25px 35px 43px 35px',
                    marginBottom: '23px',
                    maxHeight: 720,
                    overflowY: 'auto',
                    justifyContent: "space-between",
                    '&::-webkit-scrollbar': {
                        width: '6px', // Largura da barra de rolagem
                        height: '6px', // Largura da barra de rolagem
                    },
                    '&::-webkit-scrollbar-thumb': {
                        backgroundColor: '#5A8292', // Cor da barra
                        borderRadius: '10px', // Borda arredondada
                        border: '1px solid trasparent', // Espaço para adicionar efeito de "padding"
                        backgroundClip: 'content-box', // Para manter o border transparente
                    },
                    '&::-webkit-scrollbar-track': {
                        backgroundColor: '#F0EFEB', // Cor do track (fundo da barra)
                        borderRadius: '10px',
                    },
                    '&::-webkit-scrollbar-thumb:hover': {
                        backgroundColor: '#555', // Cor ao passar o mouse sobre a barra
                    },
                }}>
                    {otherData.map((i) => {
                        return (
                            <Grid sx={{ display: 'flex', flexDirection: 'column' }}>
                                <Typography style={{ fontSize: '15px', color: '#142A30', fontWeight: 700, paddingBottom: '15px' }}>
                                    {i.label}
                                </Typography>
                                <Typography style={{ fontSize: '15px', color: '#3D3D3D', fontWeight: 400 }}>
                                    {i.value}
                                </Typography>
                            </Grid>
                        );
                    })}
                </Paper>

                <Paper xs={12} elevation={0} sx={{
                    width: '100%',
                    display: 'flex',
                    borderTop: '2px solid #142A3099',
                    flexDirection: screenWidth <= 1630 ? 'column' : 'row',
                }}>

                    <Grid sx={{
                        paddingTop: '31px',
                        width: screenWidth <= 1630 ? '100%' : '30%',
                        paddingBottom: screenWidth <= 1630 ? '20px' : null
                    }}>
                        <Typography style={{ fontSize: '24px', color: '#142A30', fontWeight: 700, paddingBottom: '9px' }}>
                            Detalhamento de liquidação
                        </Typography>
                        <Typography style={{ maxWidth: '35ch', fontSize: '16px', color: '#999999', fontWeight: 500, paddingBottom: '35px' }}>
                            Acompanhe com mais detalhes a sua venda ser liquidada e tornar-se um recebível.
                        </Typography>
                        {/* <Button sx={{
                            backgroundColor: '#17333A',
                            padding: '15px 17px',
                            borderRadius: '20px',
                            '&:hover': {
                                backgroundColor: '#17333A'
                            },
                            '&:focus': {
                                backgroundColor: '#17333A'
                            },
                            '&:active': {
                                backgroundColor: '#17333A'
                            },
                        }}>
                            <Typography sx={{ textTransform: 'none', color: '#FEFDF9', paddingRight: '15px' }}>
                                Ver minha conta bancária
                            </Typography>
                            <FontAwesomeIcon
                                icon={faChevronRight}
                                style={{ fontSize: '15px', color: "#FEFDF9", cursor: 'pointer', paddingRight: '3px' }}
                            />
                        </Button> */}
                    </Grid>

                    <Grid sx={{
                        width: screenWidth <= 1630 ? '100%' : '70%',
                        maxHeight: '258px',
                        display: 'flex',
                        flexDirection: 'column',
                        overflowY: 'auto',
                        '&::-webkit-scrollbar': {
                            width: '6px', // Largura da barra de rolagem
                            height: '6px', // Largura da barra de rolagem
                        },
                        '&::-webkit-scrollbar-thumb': {
                            backgroundColor: '#5A8292', // Cor da barra
                            borderRadius: '10px', // Borda arredondada
                            border: '1px solid trasparent', // Espaço para adicionar efeito de "padding"
                            backgroundClip: 'content-box', // Para manter o border transparente
                        },
                        '&::-webkit-scrollbar-track': {
                            backgroundColor: '#F0EFEB', // Cor do track (fundo da barra)
                            borderRadius: '10px',
                        },
                        '&::-webkit-scrollbar-thumb:hover': {
                            backgroundColor: '#555', // Cor ao passar o mouse sobre a barra
                        },
                    }}>
                        {parcelamentosData.map((row, index) => (
                            <Grid key={index} sx={{
                                display: 'flex',
                                paddingBottom: '10px',
                                justifyContent: 'space-between',
                            }}>
                                {plotColumns.map((column) => (
                                    <Grid key={column.key || column.label}
                                        sx={{
                                            width: '100%',
                                            display: 'flex',
                                            flexDirection: 'column',
                                            backgroundColor: '#F1F1F1',
                                            padding: column.key == 'idvenda' ? '25px 0px 25px 52px' : '25px 25px 0px 0px',
                                        }}>
                                        {column.label === 'ID#' ?
                                            <Typography style={{ fontSize: '15px', color: '#3D3D3D', fontWeight: 400 }}>
                                                Parcelado {index + 1}/{parcelamentosData.length}
                                            </Typography> :
                                            column.label === 'Data de Vencimento' ?
                                                <Typography style={{ width: '15ch', fontSize: '15px', color: '#142A30', fontWeight: 700, paddingBottom: '10px' }}>
                                                    {column.label}
                                                </Typography> :
                                                <Typography style={{ width: '12ch', fontSize: '15px', color: '#142A30', fontWeight: 700, paddingBottom: '10px' }}>
                                                    {column.label}
                                                </Typography>
                                        }

                                        {column.key === 'idvenda' ?
                                            <Typography style={{ width: '12ch', fontSize: '15px', color: '#3D3D3D', fontWeight: 700 }}>
                                                {index + 1}º Parcela
                                            </Typography> :
                                            <Typography style={{ width: '10ch', fontSize: '15px', color: '#3D3D3D', fontWeight: 400 }}>
                                                {column.key === 'datavencimento' && rows[0].bandeira == 'Xuliz' ? format(row[column.key], 'dd/MM/yyyy') : row[column.key]}
                                            </Typography>
                                        }

                                        {column.key === 'status' && (
                                            statusImagemAprovacao(row.datavencimento, row) ?
                                                <Typography style={{ fontSize: '15px', color: '#7FA800', fontWeight: 700 }}>
                                                    Pago
                                                </Typography> :
                                                <Typography style={{ fontSize: '15px', color: '#E17E36', fontWeight: 700 }}>
                                                    Agendado
                                                </Typography>
                                        )}
                                    </Grid>
                                ))}
                            </Grid>
                        ))}
                    </Grid>

                </Paper>

            </Grid>

        </Grid >
    );
};

export default DetalhesVendas;

const styles = Stylesheet.create({
    content: {
        margin: '40px 50px 16px 70px'
    },
    contentSmallScreen: {
        margin: '30px 25px'
    },
    contentMobile: {
        margin: '0px 10px'
    },
    customSeparator: {
        alignItems: 'center',
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: '23px'
    },
    customSeparatorSmallScreen: {
        alignItems: 'center',
        display: 'flex',
        justifyContent: 'space-around',
        marginBottom: '20px'
    },
    titleBigPrice: {
        color: '#999999',
        fontWeight: 400,
        paddingBottom: '5px'
    },
    valueBigPrices: {
        fontWeight: 600
    },
    textTable: {
        color: '#142A30',
        fontSize: '15px',
        fontWeight: 700,
        textAlign: 'end',
        marginBottom: 0,
    },
    cashbackTable: {
        color: '#279C33',
        fontSize: '15px',
        fontWeight: 700,
        textAlign: 'end',
        marginBottom: 0,
    },
    taxaTable: {
        color: '#E95454',
        fontSize: '15px',
        fontWeight: 700,
        textAlign: 'end',
        marginBottom: 0,
    },
    statusTable: {
        color: '#7FA800',
        fontSize: '15px',
        fontWeight: 700,
        textAlign: 'end',
        marginBottom: 0,
    },
    actionTable: {
        cursor: 'pointer',
        color: '#5A8292',
        fontSize: '15px',
        fontWeight: 700,
        marginBottom: 0,
        marginLeft: '48px',
        fontSize: 17,
        fontWeight: 'bold'
    }
})