import { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import bagOn from "../assets/icons/bagOn.png"
import bagOff from "../assets/icons/bagOff.png"
import marketingOn from "../assets/icons/funnelOn.png"
import marketingOff from "../assets/icons/funnelOff.png"
import dolarOn from "../assets/icons/dolarOn.png"
import dolarOff from "../assets/icons/dolarOff.png"
import arrow from "../assets/icons/arrowRigthWhite.png"

import sair from "../assets/icons/exit.png"
import configuracoes from "../assets/icons/config.png"
import logo_parceiros from '../assets/logos/logo.parceiros.png';
import logo from '../assets/logos/logo.png';
import MenuOpen from "./MenuOpen";
import MenuClose from "./MenuClose";


const MenuMobile = ({ page, menuOpen, setMenuOpen }) => {
    const navigate = useNavigate();
    const Swal = require('sweetalert2')

    const [pageRef, setPageRef] = useState(page);
    const [emailRef, setEmailRef] = useState('');
    const [usuario, setUsuario] = useState('');
    const [urlLogo, setUrlLogo] = useState('');
    // const [menuOpen, setMenuOpen] = useState(true);


    // useEffect(() => {
    //     updateDimensions();
    //     window.addEventListener('resize', updateDimensions);

    //     return () => {
    //         window.removeEventListener('resize', updateDimensions);
    //     };
    // }, []);

    // const updateDimensions = () => {
    //     if (window.innerWidth <= 1400 && menuOpen) {
    //         setMenuOpen(false)
    //     }
    //     if (window.innerWidth > 1400 && menuOpen) {
    //         setMenuOpen(true)
    //     }
    // };

    const onGroupContainer13Click = useCallback(() => {
        localStorage.clear()
        navigate("/");
    }, [navigate]);

    useEffect(() => {
        getDados()
    }, []);


    const getDados = async () => {
        var dados = await localStorage.getItem('login')

        if (dados == null) {
            return false;
        }

        dados = JSON.parse(dados)
        var dadosX = dados;

        setEmailRef(dadosX.nome)
        setUsuario(dadosX.empresa)
        setUrlLogo(dadosX.urllogo)

        verificarCDL(dadosX)
    }

    const verificarCDL = (dadosX) => {
        var idparceiro = dadosX.parceiros;

        if (idparceiro == null) {
            document.getElementById('menu_marketing').style.display = 'none'
            return false;
        }

        if (idparceiro.length == 0) {
            document.getElementById('menu_marketing').style.display = 'none'
            return false;
        }

        if (idparceiro[0] == null) {
            document.getElementById('menu_marketing').style.display = 'none'
            return false;
        }
    }

    const irPage = (page) => {
        location.href = './' + page
    }

    return (
        <>

            {menuOpen ?
                <MenuOpen
                    setMenuOpen={setMenuOpen}
                    logo_parceiros={logo_parceiros}
                    bagOn={bagOn}
                    bagOff={bagOff}
                    marketingOn={marketingOn}
                    marketingOff={marketingOff}
                    dolarOn={dolarOn}
                    dolarOff={dolarOff}
                    sair={sair}
                    configuracoes={configuracoes}
                    arrow={arrow}
                    pageRef={pageRef}
                    emailRef={emailRef}
                    usuario={usuario}
                    urlLogo={urlLogo}
                    irPage={irPage}
                    onGroupContainer13Click={onGroupContainer13Click}
                />
                :
                <MenuClose
                    setMenuOpen={setMenuOpen}
                    logo={logo}
                    bagOn={bagOn}
                    bagOff={bagOff}
                    marketingOn={marketingOn}
                    marketingOff={marketingOff}
                    dolarOn={dolarOn}
                    dolarOff={dolarOff}
                    sair={sair}
                    configuracoes={configuracoes}
                    arrow={arrow}
                    pageRef={pageRef}
                    usuario={usuario}
                    urlLogo={urlLogo}
                    irPage={irPage}
                    onGroupContainer13Click={onGroupContainer13Click}
                />
            }
        </>
    )
}

export default MenuMobile;
