import { Box, Grid, IconButton, TextField, Typography } from "@mui/material";
import CustomSeparator from "../componentes/Breadcrumb";
import Stylesheet from "reactjs-stylesheet";
import Calendario from "./calendario";
import CalendarioMobile from "./calendarioMobile";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft, faChevronRight, faCircle, faEllipsisVertical } from "@fortawesome/free-solid-svg-icons";
import { useEffect, useState } from "react";
import { format } from "date-fns";
import { useNavigate } from "react-router-dom";

const Recebiveis = ({
    isMobile,
    screenWidth,
    mes,
    ano,
    handleMonthChangeMobile,
    dataCalendario,
    handleSelectChange,
    geralBruto,
    totalPedidos,
    geralCredito,
    geralDebito,
    geralXuliz,
    geralTaxas,
    total_receber,
    toggleValue,
    selectedValues,
    dadosREF,
    dtIniList,
    dtEndList
}) => {

    const navigate = useNavigate();
    const [valuesFiltered, setValuesFiltered] = useState({
        XulizValor: 0,
        CreditoVValor: 0,
        CreditoPValor: 0,
        PixValor: 0,
        DebitoValor: 0,
        Valor: 0,
        ValorBruto: 0,
        QtdeTotal: 0,
        RecebeHoje: 0,
        VouReceber: 0,
    });
    const today = new Date();
    const tomorrow = new Date();
    const hoje = format(today, 'yyyy-MM-dd');
    const amanha = format(tomorrow, 'yyyy-MM-dd');
    const month = parseInt(mes, 10);
    const year = parseInt(ano, 10);
    const agora = format(new Date(), 'HH:mm');
    const firstDay = new Date(year, month, 1).getDay();
    const lastDate = new Date(year, month + 1, 0).getDate();
    const emptyDaysBefore = Array.from({ length: firstDay }, () => ({ empty: true }));

    const daysInMonth = Array.from({ length: lastDate }, (_, index) => {
        const day = index + 1; // O dia do mês (1 a 31)
        const formattedDate = `${year}-${String(month + 1).padStart(2, '0')}-${String(day).padStart(2, '0')}`; // Formata a data como YYYY-MM-DD

        const dayData = dataCalendario.find((i) => i.data === formattedDate);

        // Retorna o dado encontrado ou cria um objeto padrão com a data completa
        return dayData || { data: formattedDate, status: '', total: 0, totalPedidos: 0 };
    });

    const allDays = [...emptyDaysBefore, ...daysInMonth];

    useEffect(() => {
        tomorrow.setDate(tomorrow.getDate() + 1);
        tomorrow.setHours(0, 0, 0, 0);

        let xulizTotal = 0;
        let creditoVTotal = 0;
        let creditoPTotal = 0;
        let pixTotal = 0;
        let debitoTotal = 0;
        let valorTotal = 0;
        let valorBrutoTotal = 0;
        let recebeHoje = 0;
        let vouReceber = 0;
        let qtdeTotal = 0;

        // Filtrar dias com base nos valores selecionados
        const filtered = selectedValues?.length > 0
            ? dadosREF?.retorno.filter(day => selectedValues.some(selected =>
                (selected.data && selected.data === day.datavencimento) ||
                (selected.day && selected.day === day.datavencimento)
            )) : dadosREF?.retorno || [];

        recebeHoje = parseFloat(dadosREF?.hoje[0]?.valor ? dadosREF?.hoje[0]?.valor : 0);
        vouReceber = parseFloat(dadosREF?.futuro[0]?.valor ? dadosREF?.futuro[0]?.valor : 0);

        // Atualizar valores totais com base nos dias filtrados
        if (filtered.length > 0) {
            filtered.forEach(day => {
                const diaSelecionado = format(new Date(`${day.datavencimento}T00:00:00`), 'yyyy-MM-dd');

                if (day.qtde) qtdeTotal += parseFloat(day.qtde);

                if (day.valor) {

                    if (diaSelecionado === hoje && day?.valor) {
                        valorTotal += parseFloat(day?.valor - day.resumo.find((i) => i.tipo == 'Pix')?.valor);
                    } else {
                        valorTotal += parseFloat(day?.valor)
                    }

                    // if (diaSelecionado === hoje && valuesFiltered.RecebeHoje <= 0) {
                    //     recebeHoje += parseFloat(day.valor - day.resumo.find((i) => i.tipo == 'Pix').valor);
                    // } else if (diaSelecionado >= amanha && valuesFiltered.VouReceber <= 0) {
                    //     vouReceber += parseFloat(day.valor);
                    // }
                }

                if (day.valorbruto && day?.valor) {
                    if (diaSelecionado === hoje) {
                        valorBrutoTotal += parseFloat(day.valorbruto - day.resumo.find((i) => i.tipo == 'Pix')?.valor);
                    } else {
                        valorBrutoTotal += parseFloat(day.valorbruto);
                    }
                }

                if (day.resumo && Array.isArray(day.resumo)) {
                    day.resumo.forEach(item => {
                        if (item.tipo === 'Xuliz APP') {
                            xulizTotal += parseFloat(item?.valor);
                        } else if (item.tipo === 'Crédito Parcelado') {
                            creditoPTotal += parseFloat(item?.valor);
                        } else if (item.tipo === 'Crédito à Vista') {
                            creditoVTotal += parseFloat(item?.valor);
                        } else if (item.tipo === 'Pix' && diaSelecionado != hoje) {
                            pixTotal += parseFloat(item?.valor);
                        } else if (item.tipo === 'Pix' && diaSelecionado == hoje) {
                            recebeHoje -= parseFloat(item?.valor);
                        } else if (item.tipo === 'Débito') {
                            debitoTotal += parseFloat(item?.valor);
                        }
                    });
                }
            });
        }

        // Atualizar o estado com os valores filtrados
        setValuesFiltered({
            XulizValor: xulizTotal,
            CreditoVValor: creditoVTotal,
            CreditoPValor: creditoPTotal,
            PixValor: pixTotal,
            DebitoValor: debitoTotal,
            Valor: valorTotal,
            ValorBruto: valorBrutoTotal,
            QtdeTotal: qtdeTotal,
            RecebeHoje: valuesFiltered.RecebeHoje == 0 ? recebeHoje : valuesFiltered.RecebeHoje,
            VouReceber: valuesFiltered.VouReceber == 0 ? vouReceber : valuesFiltered.VouReceber,
        });

    }, [selectedValues, dadosREF?.retorno]);

    const formatCurrency = (value) => {
        const valueStr = String(value);
        const cleanedValue = valueStr.replace(/[^\d.-]/g, '');
        const numberValue = parseFloat(cleanedValue);

        if (isNaN(numberValue)) {
            return '';
        }
        return new Intl.NumberFormat('pt-BR', {
            style: 'currency',
            currency: 'BRL'
        }).format(numberValue);
    };

    function formatarPeriodo(dtIniList, dtEndList) {
        if (dtIniList && dtEndList) {
            // Dividindo as strings de data diretamente para evitar problemas de fuso horário

            const [anoIni, mesIni, diaIni] = dtIniList?.split('-')?.map(Number);
            const [anoFim, mesFim, diaFim] = dtEndList?.split('-')?.map(Number);

            // Mapeia os meses para seus respectivos nomes em português
            const meses = [
                'Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho',
                'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'
            ];

            // Obtém o nome do mês de dtIniList
            const mes = meses[mesIni - 1];

            if (selectedValues.length == 1) {
                const [anoFimUK, mesFimUK, diaFimUK] = selectedValues[0].data.split('-').map(Number);
                // Retorna apenas o dia, se for um período de um único dia
                return `${diaFimUK} de ${mes}`;
            } else {
                // Se forem diferentes, retorna o intervalo
                return `${diaIni} à ${diaFim} de ${mes}`;
            }
        }
    }

    function calcMimMax(min, max, divide) {
        const calculated = screenWidth / divide;

        if (calculated > min && calculated < max) {
            return calculated;
        } else if (calculated < min) {
            return min;
        } else if (calculated > max) {
            return max;
        }

        return 16;
    }

    const meses = [
        'Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho',
        'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro',
    ];

    return (
        <Grid item xs sx={isMobile ? styles.contentMobile : screenWidth <= 1630 ? styles.contentSmallScreen : styles.content}>

            <Grid container spacing={0} style={{ width: '100%', display: "flex", flexDirection: 'row', justifyContent: 'space-between' }}>

                <Grid sx={{
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'space-between'
                }}>

                    {isMobile ? null :
                        <Grid sx={{
                            display: 'flex',
                            maxWidth: '100%',
                            flexDirection: screenWidth <= 1250 ? 'column' : 'row',
                            justifyContent: 'space-between',
                            paddingBottom: screenWidth <= 1020 ? '20px' : null,
                        }}>

                            <Grid sx={{
                                width: screenWidth <= 1250 ? '100%' : screenWidth <= 1750 ? '62%' : '57%',
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'space-between',
                                paddingBottom: '18px',
                            }}>
                                {isMobile ? null :
                                    <Grid sx={[screenWidth <= 1450 ? styles.customSeparatorSmallScreen : styles.customSeparator, { paddingBottom: screenWidth <= 1250 ? '15px' : null }]}>
                                        <CustomSeparator menuOption={'Recebiveis'} item={0} props={{ dtIni: selectedValues[0], dtEnd: selectedValues[selectedValues.length - 1] }} />
                                    </Grid>
                                }

                                {/* <Grid sx={{ height: '35px', borderBottom: '1.5px solid #142A3066' }} /> */}

                                <Grid sx={{ paddingBottom: '6px' }}>
                                    <Grid sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                        <Typography sx={{ width: '66%', fontSize: calcMimMax(11, 15, 94), color: '#142A3099' }}>
                                            Selecione no calendário uma data que gostaria de iniciar uma consulta
                                            nas suas vendas. Para consultar período, marque mais de uma data.
                                        </Typography>
                                        <Grid>
                                            <TextField
                                                select
                                                variant="outlined"
                                                sx={{ ...styles.customInputNumber, width: '208px' }}
                                                value={mes}
                                                onChange={handleSelectChange}
                                                SelectProps={{
                                                    native: true,
                                                }}
                                            >
                                                <option value="0">Janeiro / {ano}</option>
                                                <option value="1">Fevereiro / {ano}</option>
                                                <option value="2">Março / {ano}</option>
                                                <option value="3">Abril / {ano}</option>
                                                <option value="4">Maio / {ano}</option>
                                                <option value="5">Junho / {ano}</option>
                                                <option value="6">Julho / {ano}</option>
                                                <option value="7">Agosto / {ano}</option>
                                                <option value="8">Setembro / {ano}</option>
                                                <option value="9">Outubro / {ano}</option>
                                                <option value="10">Novembro / {ano}</option>
                                                <option value="11">Dezembro / {ano}</option>
                                            </TextField>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>

                            <Grid sx={{
                                width: screenWidth <= 1250 ? '100%' : screenWidth <= 1750 ? '37%' : '42%', paddingBottom: '18px',
                                display: screenWidth <= 1250 ? 'flex' : null,
                                flexCirection: screenWidth <= 1250 ? 'row' : null,
                                justifyContent: screenWidth <= 1250 ? 'space-between' : null,
                                gap: screenWidth <= 1250 ? '10px' : null
                            }}>
                                <Grid sx={{
                                    width: screenWidth <= 1250 ? '100%' : null,
                                    bgcolor: '#F0EFEB',
                                    padding: `11px ${calcMimMax(15, 35, 69)}px`,
                                    alignItems: 'center',
                                    display: 'flex',
                                    flexDirection: 'row',
                                    justifyContent: 'space-between',
                                    borderRadius: '20px',
                                    marginBottom: screenWidth <= 1250 ? null : '15px'
                                }}>
                                    <Grid sx={{
                                        display: 'flex',
                                        flexDirection: 'column'
                                    }}>
                                        <Typography sx={{ fontSize: '14px', color: '#142A3099' }}>
                                            Vou receber
                                        </Typography>
                                        <Typography sx={{ fontSize: calcMimMax(16, 20, 100), fontWeight: 600, color: '#142A3099', marginTop: '-8px' }}>
                                            Hoje
                                        </Typography>
                                    </Grid>
                                    <Typography sx={{ fontSize: calcMimMax(20, 40, 49), fontWeight: 700, color: '#142A30' }}>
                                        {valuesFiltered.RecebeHoje ? formatCurrency(valuesFiltered.RecebeHoje) : 'R$ 0,00'}
                                    </Typography>
                                </Grid>
                                <Grid sx={{
                                    width: screenWidth <= 1250 ? '100%' : null,
                                    padding: `11px ${calcMimMax(15, 35, 69)}px`,
                                    alignItems: 'center',
                                    display: 'flex',
                                    flexDirection: 'row',
                                    justifyContent: 'space-between',
                                    borderRadius: '20px',
                                    border: '1px solid #F0EFEB'
                                }}>
                                    <Grid sx={{
                                        display: 'flex',
                                        flexDirection: 'column'
                                    }}>
                                        <Typography sx={{ fontSize: '14px', color: '#142A3099' }}>
                                            Vou receber
                                        </Typography>
                                        <Typography sx={{ fontSize: calcMimMax(16, 20, 100), fontWeight: 600, color: '#142A3099', marginTop: '-8px' }}>
                                            Futuramente
                                        </Typography>
                                    </Grid>
                                    <Typography sx={{ fontSize: calcMimMax(20, 40, 49), fontWeight: 700, color: '#142A30' }}>
                                        {valuesFiltered.VouReceber ? formatCurrency(valuesFiltered.VouReceber) : 'R$ 0,00'}
                                    </Typography>
                                </Grid>
                            </Grid>

                        </Grid>
                    }
                    <Grid sx={{
                        display: 'flex',
                        flexDirection: screenWidth <= 1250 ? 'column' : 'row',
                        justifyContent: 'space-between'
                    }}>
                        {isMobile ?
                            <Grid sx={{
                                width: '100%',
                                height: '390px',
                                borderRadius: '15px',
                                border: '1px solid #999999',
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'center',
                                alignItems: 'center',
                                marginBottom: screenWidth <= 1250 ? '20px' : null
                            }}>
                                <Box display="flex" alignItems="center" justifyContent="center">
                                    <IconButton onClick={() => handleMonthChangeMobile('previous')}>
                                        <FontAwesomeIcon
                                            icon={faChevronLeft}
                                            style={{ width: '15px', height: '15px', color: "#142A30", cursor: 'pointer', paddingRight: '3px' }}
                                        />
                                    </IconButton>

                                    <Typography sx={{ width: '208px', textAlign: 'center', fontWeight: 700, fontSize: '14px' }}>
                                        {meses[mes]}
                                    </Typography>

                                    <IconButton onClick={() => handleMonthChangeMobile('next')}>
                                        <FontAwesomeIcon
                                            icon={faChevronRight}
                                            style={{ width: '15px', height: '15px', color: "#142A30", cursor: 'pointer', paddingRight: '3px' }}
                                        />
                                    </IconButton>
                                </Box>

                                <Grid sx={{ width: '90%', marginY: '10px', border: '1px solid #F1F1F1' }} />

                                <CalendarioMobile
                                    agora={agora}
                                    screenWidth={screenWidth}
                                    allDays={allDays}
                                    toggleValue={toggleValue}
                                    formatCurrency={formatCurrency}
                                    selectedValues={selectedValues} />

                                <Grid sx={{ display: 'flex', flexDirection: 'row', paddingTop: '15px' }}>
                                    <Typography sx={styles.legendText}>
                                        <FontAwesomeIcon
                                            icon={faCircle}
                                            style={{ width: '15px', height: '15px', marginRight: '5px', color: '#9DE200', borderRadius: '20px' }}
                                        />
                                        Pago
                                    </Typography>
                                    <Typography sx={styles.legendText}>
                                        <FontAwesomeIcon
                                            icon={faCircle}
                                            style={{ width: '15px', height: '15px', marginRight: '5px', color: '#FFB74D', borderRadius: '20px' }}
                                        />
                                        A receber
                                    </Typography>
                                    <Typography sx={styles.legendText}>
                                        <FontAwesomeIcon
                                            icon={faCircle}
                                            style={{ width: '15px', height: '15px', marginRight: '5px', color: '#E57373', borderRadius: '20px' }}
                                        />
                                        Sem venda
                                    </Typography>
                                </Grid>
                            </Grid>
                            :
                            <Grid sx={{
                                width: screenWidth <= 1250 ? '100%' : screenWidth <= 1750 ? '62%' : '57%',
                                marginBottom: screenWidth <= 1250 ? '20px' : null
                            }}>
                                <Calendario
                                    hoje={hoje}
                                    agora={agora}
                                    screenWidth={screenWidth}
                                    allDays={allDays}
                                    toggleValue={toggleValue}
                                    formatCurrency={formatCurrency}
                                    selectedValues={selectedValues} />
                            </Grid>
                        }
                        <Grid sx={{
                            width: screenWidth <= 1250 ? '100%' : screenWidth <= 1750 ? '37%' : '42%',
                            bgcolor: '#F0EFEB',
                            borderRadius: '20px',
                            maxHeight: allDays.length >= 36 ? '846px' : screenWidth <= 1050 ? '675px' : '763px',
                        }}>

                            <Grid sx={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                                padding: `${calcMimMax(13, 43, 40)}px`,
                                flexDirection: 'row'
                            }}>
                                <Grid>
                                    <Typography sx={{ fontSize: calcMimMax(12, 14, 100), fontWeight: 600, color: '#142A3099' }}>
                                        Detalhamento calendário
                                    </Typography>
                                    <Typography sx={{ fontSize: calcMimMax(12, 14, 100), color: '#142A3099' }}>
                                        {formatarPeriodo(dtIniList ? dtIniList : null, dtEndList ? dtEndList : null)}
                                    </Typography>
                                </Grid>
                                <Grid sx={{
                                    color: '#FFFFFF',
                                    bgcolor: '#142A3066',
                                    padding: '10px',
                                    borderRadius: '20px'
                                }}>
                                    <Typography sx={{ fontSize: calcMimMax(12, 14, 100), fontWeight: 600, }}>
                                        {valuesFiltered.QtdeTotal ? valuesFiltered.QtdeTotal : totalPedidos} vendas
                                    </Typography>
                                </Grid>
                            </Grid>

                            <Grid sx={{ marginBottom: `${calcMimMax(5, 43, 100)}px`, border: '3px solid #17333A' }} />

                            <Grid sx={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                flexDirection: 'column',
                                paddingInline: screenWidth <= 1450 ? '13px' : '40px'
                            }}>

                                <Typography sx={{ fontSize: '20px', fontWeight: 700, paddingBottom: '38px' }}>
                                    Lançamentos no período
                                </Typography>

                                <Grid sx={{ display: 'flex', flexDirection: 'column', paddingBottom: '63px' }}>

                                    <Grid sx={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        borderBottom: '1px solid #142A3066',
                                        justifyContent: 'space-between',
                                        paddingBottom: '10px',
                                        marginBottom: '10px'
                                    }}
                                        onClick={() => {
                                            navigate('/resumo_recebiveis',
                                                {
                                                    state: {
                                                        param: {
                                                            dtIni: selectedValues[0],
                                                            dtEnd: selectedValues[selectedValues.length - 1],
                                                            filter: 'Crédito'
                                                        }
                                                    }
                                                })
                                        }}
                                    >
                                        <Typography sx={{ fontSize: '15px', alignItems: 'center' }}>
                                            Crédito à vista
                                        </Typography>
                                        <Grid sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                            <Typography sx={{ fontSize: '15px', fontWeight: 600, paddingRight: '9px' }}>
                                                {valuesFiltered.CreditoVValor ? formatCurrency(valuesFiltered.CreditoVValor) :
                                                    valuesFiltered.CreditoVValor == 0 ? formatCurrency(valuesFiltered.CreditoVValor) : geralCredito}
                                            </Typography>
                                            <FontAwesomeIcon
                                                icon={faEllipsisVertical}
                                                style={{ width: '15px', height: '15px', color: "#142A30", cursor: 'pointer', paddingRight: '3px' }}
                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid sx={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        borderBottom: '1px solid #142A3066',
                                        justifyContent: 'space-between',
                                        paddingBottom: '10px',
                                        marginBottom: '10px'
                                    }}
                                        onClick={() => {
                                            navigate('/resumo_recebiveis',
                                                {
                                                    state: {
                                                        param: {
                                                            dtIni: selectedValues[0],
                                                            dtEnd: selectedValues[selectedValues.length - 1],
                                                            filter: 'Débito'
                                                        }
                                                    }
                                                })
                                        }}
                                    >
                                        <Typography sx={{ fontSize: '15px', alignItems: 'center' }}>
                                            Débito
                                        </Typography>
                                        <Grid sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                            <Typography sx={{ fontSize: '15px', fontWeight: 600, paddingRight: '9px' }}>
                                                {valuesFiltered.DebitoValor ? formatCurrency(valuesFiltered.DebitoValor) :
                                                    valuesFiltered.DebitoValor == 0 ? formatCurrency(valuesFiltered.DebitoValor) : geralDebito}
                                            </Typography>
                                            <FontAwesomeIcon
                                                icon={faEllipsisVertical}
                                                style={{ width: '15px', height: '15px', color: "#142A30", cursor: 'pointer', paddingRight: '3px' }}
                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid sx={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        borderBottom: '1px solid #142A3066',
                                        justifyContent: 'space-between',
                                        paddingBottom: '10px',
                                        marginBottom: '10px'
                                    }}
                                        onClick={() => {
                                            navigate('/resumo_recebiveis',
                                                {
                                                    state: {
                                                        param: {
                                                            dtIni: selectedValues[0],
                                                            dtEnd: selectedValues[selectedValues.length - 1],
                                                            filter: 'Crédito parcelado'
                                                        }
                                                    }
                                                })
                                        }}
                                    >
                                        <Typography sx={{ fontSize: '15px', alignItems: 'center' }}>
                                            Crédito parcelado
                                        </Typography>
                                        <Grid sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                            <Typography sx={{ fontSize: '15px', fontWeight: 600, paddingRight: '9px' }}>
                                                {valuesFiltered.CreditoPValor ? formatCurrency(valuesFiltered.CreditoPValor) :
                                                    valuesFiltered.CreditoPValor == 0 ? formatCurrency(valuesFiltered.CreditoPValor) : "R$ 0,00"}
                                            </Typography>
                                            <FontAwesomeIcon
                                                icon={faEllipsisVertical}
                                                style={{ width: '15px', height: '15px', color: "#142A30", cursor: 'pointer', paddingRight: '3px' }}
                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid sx={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        borderBottom: '1px solid #142A3066',
                                        justifyContent: 'space-between',
                                        paddingBottom: '10px',
                                        marginBottom: '10px'
                                    }}
                                        onClick={() => {
                                            navigate('/resumo_recebiveis',
                                                {
                                                    state: {
                                                        param: {
                                                            dtIni: selectedValues[0],
                                                            dtEnd: selectedValues[selectedValues.length - 1],
                                                            filter: 'Xuliz'
                                                        }
                                                    }
                                                })
                                        }}
                                    >
                                        <Typography sx={{ fontSize: '15px', alignItems: 'center' }}>
                                            Xulis App
                                        </Typography>
                                        <Grid sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                            <Typography sx={{ fontSize: '15px', fontWeight: 600, paddingRight: '9px' }}>
                                                {valuesFiltered.XulizValor ? formatCurrency(valuesFiltered.XulizValor) :
                                                    valuesFiltered.XulizValor == 0 ? formatCurrency(valuesFiltered.XulizValor) : geralXuliz}
                                            </Typography>
                                            <FontAwesomeIcon
                                                icon={faEllipsisVertical}
                                                style={{ width: '15px', height: '15px', color: "#142A30", cursor: 'pointer', paddingRight: '3px' }}
                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid sx={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        borderBottom: '1px solid #142A3066',
                                        justifyContent: 'space-between',
                                        paddingBottom: '10px',
                                        marginBottom: '10px'
                                    }}
                                        onClick={() => {
                                            navigate('/resumo_recebiveis',
                                                {
                                                    state: {
                                                        param: {
                                                            dtIni: selectedValues[0],
                                                            dtEnd: selectedValues[selectedValues.length - 1],
                                                            filter: 'Pix'
                                                        }
                                                    }
                                                })
                                        }}
                                    >
                                        <Typography sx={{ fontSize: '15px', alignItems: 'center' }}>
                                            Pix
                                        </Typography>
                                        <Grid sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                            <Typography sx={{ fontSize: '15px', fontWeight: 600, paddingRight: '9px' }}>
                                                {valuesFiltered.PixValor ? formatCurrency(valuesFiltered.PixValor) :
                                                    valuesFiltered.PixValor == 0 ? formatCurrency(valuesFiltered.PixValor) : "Em contagem"}
                                            </Typography>
                                            <FontAwesomeIcon
                                                icon={faEllipsisVertical}
                                                style={{ width: '15px', height: '15px', color: "#142A30", cursor: 'pointer', paddingRight: '3px' }}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>

                                <Grid sx={{ display: 'flex', flexDirection: 'column', paddingBottom: `${calcMimMax(40, 58, 26)}px` }}>

                                    <Grid sx={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        justifyContent: 'flex-end',
                                        paddingBottom: '10px'
                                    }}>
                                        <Typography sx={{ color: '#142A3099', fontSize: '15px', paddingRight: '34px' }}>
                                            Total bruto:
                                        </Typography>
                                        <Typography sx={{ color: '#142A30', fontSize: '15px', fontWeight: 600 }}>
                                            {valuesFiltered.ValorBruto ? formatCurrency(valuesFiltered.ValorBruto) : 'R$ 0,00'}
                                        </Typography>
                                    </Grid>

                                    <Grid sx={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        justifyContent: 'flex-end',
                                        paddingBottom: '10px'
                                    }}>
                                        <Typography sx={{ color: '#142A3099', fontSize: '15px', paddingRight: '34px' }}>
                                            Taxa/tarifa paga:
                                        </Typography>
                                        <Typography sx={{ color: '#E57373', fontSize: '15px', fontWeight: 600 }}>
                                            -{valuesFiltered.ValorBruto && valuesFiltered.Valor ? formatCurrency(valuesFiltered.ValorBruto - valuesFiltered.Valor) : 'R$ 0,00'}
                                        </Typography>
                                    </Grid>

                                    {/* <Grid sx={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        justifyContent: 'flex-end',
                                        paddingBottom: '10px'
                                    }}>
                                        <Typography sx={{ color: '#142A3099', fontSize: '15px', paddingRight: '34px' }}>
                                            Cashback:
                                        </Typography>
                                        <Typography sx={{ color: '#7FA800', fontSize: '15px', fontWeight: 600 }}>
                                            R$1.597,65
                                        </Typography>
                                    </Grid> */}

                                </Grid>

                                <Grid sx={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    justifyContent: 'space-between',
                                    paddingBottom: '57px'
                                }}>
                                    <Typography sx={{ color: '#142A3099', fontSize: '20px', fontWeight: 600, }}>
                                        Total líquido:
                                    </Typography>
                                    <Typography sx={{ color: '#142A30', fontSize: '20px', fontWeight: 700 }}>
                                        {valuesFiltered.Valor ? formatCurrency(valuesFiltered.Valor) :
                                            valuesFiltered.Valor == 0 ? formatCurrency(valuesFiltered.Valor) : total_receber}
                                    </Typography>
                                </Grid>

                            </Grid>

                        </Grid>

                    </Grid>

                </Grid>

            </Grid >

        </Grid >
    )
}

export default Recebiveis;

const styles = Stylesheet.create({
    content: {
        margin: '40px 50px 16px 70px'
    },
    contentSmallScreen: {
        margin: '45px 25px'
    },
    contentMobile: {
        margin: '20px 10px'
    },
    customSeparator: {
        alignItems: 'center',
        display: 'flex',
        justifyContent: 'space-between',
        marginTop: '15px'
    },
    customInputNumber: {
        height: '45px',
        borderRadius: '15px',
        border: '1px solid #142A30',
        display: 'flex',
        justifyContent: 'center',
        '& .MuiOutlinedInput-notchedOutline': {
            border: 'none',
        },
        '& .MuiInputBase-input': {
            fontSize: '15px',
            color: '#142A30',
            fontWeight: 'bold',
            padding: '15px 17px',
        },
        '& input[type=number]::-webkit-outer-spin-button, & input[type=number]::-webkit-inner-spin-button': {
            WebkitAppearance: 'none',
            margin: 0,
        },
    },
    legendText: {
        padding: '5px',
        color: '#142A30',
        fontWeight: 700,
        fontSize: '11px',
        display: 'flex',
        justifyContent: 'center'
    }
})