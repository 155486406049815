import * as React from 'react';
import { format } from "date-fns";
import { Table } from "react-bootstrap";
import Stylesheet from "reactjs-stylesheet";
import trash from '../assets/icons/trash.png'
import { Box, Button, Collapse, Grid, IconButton, Paper, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material";
import "./css.css";

const Content = ({ screenWidth, allMembros }) => {

    // console.log('allMembros', allMembros)

    const columns = [
        { Ações: 'Ações', label: 'Ações' },
        { Relaçãocomempresa: 'Relação com empresa', label: 'Relação com empresa' },
        { DataCadastro: 'Data  cadastro', label: 'Data  cadastro' },
        { Situação: 'Situação', label: 'Situação' },
        { celular: 'Número celular', label: 'Número celular' },
        { Nome: 'Nome', label: 'Nome' },
        { idusuario: '#ID', label: '#ID' },
    ];

    const palavrasExcecao = ['de', 'a', 'o', 'para', 'em', 'com', 'do', 'da', 'dos', 'das', 'se', 'um', 'uma'];

    const getInitials = (name) => {
        const nameParts = name.trim().split(' ').filter(part => !palavrasExcecao.includes(part.toLowerCase()));
        // Pegamos as iniciais das três primeiras partes, ignorando as palavras de conexão
        const firstInitial = nameParts[0] ? nameParts[0][0] : '';
        const secondInitial = nameParts[1] ? nameParts[1][0] : '';
        const thirdInitial = nameParts[2] ? nameParts[2][0] : '';
        return (firstInitial + secondInitial + thirdInitial).toUpperCase();
    };

    // const tableMembros = (row, index) => {
    //     return (
    //         <TableRow key={index} sx={{ maxHeight: '20px' }}>
    //             <TableCell>
    //                 <Grid sx={{
    //                     display: 'flex',
    //                     alignItems: 'center',
    //                     justifyContent: 'flex-start'
    //                 }}>
    //                     <Typography sx={{
    //                         width: '45px',
    //                         height: '45px',
    //                         display: 'flex',
    //                         fontWeight: 600,
    //                         color: '#FFFFFF',
    //                         bgcolor: '#17333A',
    //                         alignItems: 'center',
    //                         borderRadius: '20px',
    //                         justifyContent: 'center',
    //                         border: '2px solid #17333A',
    //                         marginRight: '28px'
    //                     }}>
    //                         {getInitials(row.nome)}
    //                     </Typography>
    //                     <Typography sx={{ fontSize: '15px', color: '#142A30', fontWeight: 600 }}>
    //                         {row.idusuario}
    //                     </Typography>
    //                 </Grid>
    //             </TableCell>
    //             <TableCell>
    //                 <Typography sx={{ fontSize: '15px', color: '#142A30', fontWeight: 600 }}>
    //                     {row.nome}
    //                 </Typography>
    //             </TableCell>
    //             <TableCell>
    //                 <Typography sx={{ fontSize: '15px', color: '#142A30', fontWeight: 600 }}>
    //                     {row.celular}
    //                 </Typography>
    //             </TableCell>
    //             <TableCell>
    //                 <Typography sx={{ fontSize: '15px', color: '#142A30', fontWeight: 600 }}>
    //                     {row.situacao}
    //                 </Typography>
    //             </TableCell>
    //             <TableCell>
    //                 <Typography sx={{ fontSize: '15px', color: '#142A30', fontWeight: 600 }}>
    //                     {format(row.datestamp, 'dd/MM/yyyy')}
    //                 </Typography>
    //             </TableCell>
    //             <TableCell>
    //                 <Typography sx={{ fontSize: '15px', color: '#142A30', fontWeight: 600 }}>
    //                     {row.dependentetipo}
    //                 </Typography>
    //             </TableCell>
    //             <TableCell>
    //                 <Grid sx={{ display: 'flex', flexDirection: 'row', gap: '11px' }}>
    //                     <Button sx={{
    //                         bgcolor: '#17333A',
    //                         padding: '10px 15px',
    //                         height: '30px',
    //                         borderRadius: '20px',
    //                         '&:hover': {
    //                             backgroundColor: '#17333A'
    //                         },
    //                     }} onClick={() => console.log('chamou2')}>
    //                         <Typography sx={{ color: '#FFFFFF', textTransform: 'none', fontSize: '15px' }}>
    //                             Sub+
    //                         </Typography>
    //                     </Button>
    //                     <Grid sx={{
    //                         display: 'flex',
    //                         width: '40px',
    //                         height: '30px',
    //                         bgcolor: '#E57373',
    //                         borderRadius: '20px',
    //                         alignItems: 'center',
    //                         justifyContent: 'space-evenly',
    //                     }}>
    //                         <img src={trash} style={{ width: '15px', height: 'auto' }} />
    //                     </Grid>
    //                 </Grid>
    //             </TableCell>
    //         </TableRow>
    //     );
    // };

    function Row(props) {
        const { row } = props;
        const [open, setOpen] = React.useState(false);

        return (
            <React.Fragment>
                <TableRow sx={{ '& > *': { borderBottom: 'none', boxShadow: 'none' } }} onClick={() => setOpen(!open)}>
                    <TableCell>
                        <Grid sx={{ display: 'flex', flexDirection: 'row', gap: '11px' }}>
                            <Button sx={{
                                bgcolor: '#17333A',
                                padding: '10px 15px',
                                height: '30px',
                                borderRadius: '20px',
                                '&:hover': {
                                    backgroundColor: '#17333A'
                                },
                            }} onClick={() => console.log('chamou2')}>
                                <Typography sx={{ color: '#FFFFFF', textTransform: 'none', fontSize: '15px' }}>
                                    Sub+
                                </Typography>
                            </Button>
                            <Grid sx={{
                                display: 'flex',
                                width: '40px',
                                height: '30px',
                                bgcolor: '#E57373',
                                borderRadius: '20px',
                                alignItems: 'center',
                                justifyContent: 'space-evenly',
                            }}>
                                <img src={trash} style={{ width: '15px', height: 'auto' }} />
                            </Grid>
                        </Grid>
                    </TableCell>
                    <TableCell>
                        <Typography sx={{ fontSize: '15px', color: '#142A30', fontWeight: 600 }}>
                            {row.dependentetipo}
                        </Typography>
                    </TableCell>
                    <TableCell>
                        <Typography sx={{ fontSize: '15px', color: '#142A30', fontWeight: 600 }}>
                            {format(row.datestamp, 'dd/MM/yyyy')}
                        </Typography>
                    </TableCell>
                    <TableCell>
                        <Typography sx={{ fontSize: '15px', color: row.situacao == "OK" ? '#7FA800' : '#FFB74D', fontWeight: 600 }}>
                            {row.situacao}
                        </Typography>
                    </TableCell>
                    <TableCell>
                        <Typography sx={{ fontSize: '15px', color: '#142A30', fontWeight: 600 }}>
                            {row.celular}
                        </Typography>
                    </TableCell>
                    <TableCell>
                        <Typography sx={{ fontSize: '15px', color: '#142A30', fontWeight: 600 }}>
                            {row.nome}
                        </Typography>
                    </TableCell>
                    <TableCell>
                        <Grid sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'flex-end'
                        }}>
                            <Typography sx={{ fontSize: '15px', color: '#142A30', fontWeight: 600 }}>
                                {row.idusuario}
                            </Typography>
                            <Typography sx={{
                                width: '45px',
                                height: '45px',
                                display: 'flex',
                                fontWeight: 600,
                                color: '#FFFFFF',
                                bgcolor: '#17333A',
                                alignItems: 'center',
                                borderRadius: '20px',
                                justifyContent: 'center',
                                border: '2px solid #17333A',
                                marginRight: '28px'
                            }}>
                                {getInitials(row.nome)}
                            </Typography>
                        </Grid>
                    </TableCell>
                </TableRow>
                {
                    row.dependentes.length > 0 ? <TableRow>
                        <TableCell colSpan={12} sx={{ border: 'none', padding: '0px !important' }}>
                            <Collapse in={open} timeout="auto" unmountOnExit>
                                <Box>
                                    <Table size="small" aria-label="purchases" sx={{ minWidth: '940px' }}>
                                        <TableBody>
                                            {row.dependentes.map((dependentes, index) => (
                                                <TableRow key={index} sx={{ bgcolor: '#F0EFEB' }}>
                                                    <TableCell sx={{
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        justifyContent: 'flex-end',
                                                        padding: '14px 0px!important',
                                                        border: 'none'
                                                    }}>
                                                        <Grid sx={{
                                                            display: 'flex',
                                                            width: '40px',
                                                            height: '30px',
                                                            bgcolor: '#E57373',
                                                            borderRadius: '20px',
                                                            alignItems: 'center',
                                                            justifyContent: 'space-evenly',
                                                            border: 'none'
                                                        }}>
                                                            <img src={trash} style={{ width: '15px', height: 'auto' }} />
                                                        </Grid>
                                                    </TableCell>
                                                    <TableCell sx={{ border: 'none' }}>
                                                        <Typography sx={{ fontSize: '14px', color: '#142A3099' }}>Situação</Typography>
                                                        <Typography sx={{ fontSize: '15px', color: dependentes.situacao == "OK" ? '#7FA800' : '#FFB74D', fontWeight: 600 }}>
                                                            {dependentes.situacao}
                                                        </Typography>
                                                    </TableCell>
                                                    <TableCell sx={{ border: 'none' }}>
                                                        <Typography sx={{ fontSize: '14px', color: '#142A3099' }}>Nº Celular</Typography>
                                                        <Typography sx={{ fontSize: '15px', color: '#142A30', fontWeight: 600 }}>
                                                            {dependentes.celular}
                                                        </Typography>
                                                    </TableCell>
                                                    <TableCell sx={{ border: 'none' }}>
                                                        <Typography sx={{ fontSize: '14px', color: '#142A3099' }}>Nome</Typography>
                                                        <Typography sx={{ fontSize: '15px', color: '#142A30', fontWeight: 600 }}>
                                                            {dependentes.nome}
                                                        </Typography>
                                                    </TableCell>
                                                    <TableCell sx={{ border: 'none' }}>
                                                        <Typography sx={{ fontSize: '14px', color: '#142A3099' }}>ID</Typography>
                                                        <Typography sx={{ fontSize: '15px', color: '#142A30', fontWeight: 600 }}>
                                                            {dependentes.idusuario}
                                                        </Typography>
                                                    </TableCell>
                                                    <TableCell sx={{ border: 'none' }}>
                                                        <img src={dependentes.urlfotoperfil} style={{ width: '45px', height: '45px', objectFit: 'cover', border: '2px solid #17333A', borderRadius: '50px' }} />
                                                    </TableCell>
                                                    <TableCell sx={{ border: 'none' }}>
                                                        <Grid sx={{
                                                            display: 'flex',
                                                            width: '25px',
                                                            height: '25px',
                                                            bgcolor: '#9DE200',
                                                            borderRadius: '20px',
                                                            alignItems: 'center',
                                                            justifyContent: 'space-evenly',
                                                        }}>
                                                            <Typography sx={{ fontSize: '15px', color: '#142A30', fontWeight: 600 }}>
                                                                {index + 1}
                                                            </Typography>
                                                        </Grid>
                                                    </TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </Box>
                            </Collapse>
                        </TableCell>
                    </TableRow> : null
                }
            </React.Fragment >
        );
    }

    return (
        // <TableContainer component={Paper} sx={{ minWidth: '1240px !important', boxShadow: 'none' }} >
        <Paper xs={12} elevation={0} sx={{ width: '100%', overflow: 'hidden' }}>
            <TableContainer xs={12}
                sx={{
                    overflowX: 'auto',
                    boxShadow: 'none',
                    direction: 'rtl', // Mantém a barra de rolagem à esquerda
                    '&::-webkit-scrollbar': {
                        width: '6px', // Largura da barra de rolagem
                        height: '6px', // Largura da barra de rolagem
                    },
                    '&::-webkit-scrollbar-thumb': {
                        backgroundColor: '#5A8292', // Cor da barra
                        borderRadius: '10px', // Borda arredondada
                        border: '1px solid trasparent', // Espaço para adicionar efeito de "padding"
                        backgroundClip: 'content-box', // Para manter o border transparente
                    },
                    '&::-webkit-scrollbar-track': {
                        backgroundColor: '#F0EFEB', // Cor do track (fundo da barra)
                        borderRadius: '10px',
                    },
                    '&::-webkit-scrollbar-thumb:hover': {
                        backgroundColor: '#555', // Cor ao passar o mouse sobre a barra
                    },
                }}>
                <Table stickyHeader aria-label="sticky table" className='tables'>
                    <TableHead>
                        <TableRow>
                            {columns.map((column) => (
                                <TableCell
                                    sx={{ fontSize: '15px', color: '#142A3099' }}
                                    key={column.codigo_autorizacao}
                                    align={column.align}
                                >
                                    {column.label}
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {allMembros.map((row) => (
                            <Row key={row.name} row={row} />
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </Paper>
    );
};

export default Content;

const styles = Stylesheet.create({
    table: {
        bgcolor: 'red',
    }
})
// sx={{ minWidth: '10%' }}