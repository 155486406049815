import { useEffect, useState } from "react";
import Stylesheet from "reactjs-stylesheet";

import Tabela from "./Tabela";
import CustomSeparator from "../componentes/Breadcrumb";

import InputMask from 'react-input-mask';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Grid, OutlinedInput, TextField, Typography } from "@mui/material";
import { faArrowUpRightFromSquare, faMinus, faPlus, faPrint } from "@fortawesome/free-solid-svg-icons";

import arrowRigthButtonBlack from "../assets/icons/arrowRigthButtonBlack.png";
import arrowRigthButtonWith from "../assets/icons/arrowRigthButtonWith.png";

import DatePicker, { registerLocale } from "react-datepicker";
import pt from 'date-fns/locale/pt'
registerLocale("pt", pt);
import "./StyleDate.css";

import ModalExport from "../componentes/ModalExport";

import 'jspdf-autotable';
import jsPDF from 'jspdf';
import { parseISO } from "date-fns";

const Minhasvendas = ({
    menuOpen,
    real,
    handleDateChange,
    startDate,
    endDate,
    toggleCalendar,
    handleChangePage,
    handleChangeRowsPerPage,
    rows,
    page,
    loading,
    isMobile,
    rowsPerPage,
    screenWidth,
    getDadosSQL,
    setStartDate
}) => {


    const bandeiraMap = {
        "Xuliz": ["XULIZ"],
        "Visa": ["Visa Crédito", "Visa Débito"],
        "Master": ["Master Crédito", "Master Débito"],
        "Elo": ["Elo Crédito", "Elo Débito"],
        "Hipercard": ["Hipercard Crédito", "Hipercard Débito"],
        "Amex": ["Amex Crédito", "Amex Débito"],
        "Pix": ["PIX"],
    };

    const statusMap = {
        "Status": ["Status"],
        "Concluída": ["Concluída"],
        "Pendente": ["Pendente"],
    }

    const [selectedBandeira, setSelectedBandeira] = useState('');
    const [selectStatus, setSelectStatus] = useState('');
    const [selectedFormaPagamento, setSelectedFormaPagamento] = useState('');
    const [valorVendaInicio, setValorVendaInicio] = useState('');
    const [valorVendaFim, setValorVendaFim] = useState('');
    const [codigoAutorizacao, setCodigoAutorizacao] = useState('');
    const [id, setId] = useState('');

    const [filteredRows, setFilteredRows] = useState(rows);
    const [plusOnOff, setPlusOnOff] = useState(false);

    const [qVendas, setQVendas] = useState(rows.length);

    const [vBruto, setVBruto] = useState(0);
    const [vLiquido, setVLiquido] = useState(0);

    const [open, setOpen] = useState(false);

    const applyFilters = () => {
        let filtered = rows;

        if (selectedBandeira && selectedBandeira.toLowerCase() !== 'bandeira') {
            const bandeiraOptions = (bandeiraMap[selectedBandeira] || []).map(option => option.toLowerCase());
            filtered = filtered.filter(i => bandeiraOptions.includes(i.bandeira.toLowerCase()));
        }

        if (selectStatus && selectStatus !== 'Status') {
            const statusOptions = statusMap[selectStatus] || [];
            filtered = filtered.filter(i => statusOptions.includes(i.status));
        }

        if (selectedFormaPagamento && selectedFormaPagamento.toLowerCase() !== 'formapagamento') {
            filtered = filtered.filter(item => item.forma_pagamento?.toLowerCase() === selectedFormaPagamento?.toLowerCase());
        }

        if (valorVendaInicio || valorVendaFim) {
            const valorVendaInicioNum = valorVendaInicio ? parseCurrency(valorVendaInicio) : -Infinity;
            const valorVendaFimNum = valorVendaFim ? parseCurrency(valorVendaFim) : Infinity;

            filtered = filtered.filter(item => {
                const itemValorVenda = parseCurrency(item.valor_venda);

                if (valorVendaInicio && !valorVendaFim || valorVendaFim == 'R$ 0,00') {
                    // Se apenas valorVendaInicio estiver definido, traz os valores maiores ou iguais a ele
                    return itemValorVenda >= valorVendaInicioNum;
                } else if (!valorVendaInicio || valorVendaInicio == 'R$ 0,00' && valorVendaFim) {
                    // Se apenas valorVendaFim estiver definido, traz os valores menores ou iguais a ele
                    return itemValorVenda <= valorVendaFimNum;
                } else {
                    // Se ambos estiverem definidos, traz os valores entre eles
                    return itemValorVenda >= valorVendaInicioNum && itemValorVenda <= valorVendaFimNum;
                }
            });
        }



        if (codigoAutorizacao) {
            filtered = filtered.filter(i => i.codigo_autorizacao.includes(codigoAutorizacao));
        }

        if (id) {
            filtered = filtered.filter(i => i.id.includes(id));
        }

        setQVendas(filtered.length)
        setFilteredRows(filtered);
    };

    useEffect(() => {
        applyFilters();
    }, [selectedBandeira, selectedFormaPagamento, valorVendaInicio, valorVendaFim, rows, codigoAutorizacao, id, selectStatus]);

    const handleBandeiraChange = (event) => {
        setSelectedBandeira(event.target.value);
    };

    const handleStatusChange = (event) => {
        setSelectStatus(event.target.value);
    };

    const handleFormaPagamentoChange = (event) => {
        setSelectedFormaPagamento(event.target.value);
    };

    const handleValorVendaInicioChange = (event) => {
        setValorVendaInicio(formatCurrency(event.target.value));
    };

    const handleValorVendaFimChange = (event) => {
        setValorVendaFim(formatCurrency(event.target.value));
    };

    const handleCodigoAutorizacaoChange = (event) => {
        setCodigoAutorizacao(event.target.value);
    };

    const handleIdChange = (event) => {
        setId(event.target.value);
    };

    const formatCurrency = (value) => {

        // Remove caracteres que não são dígitos, pontos ou hífens
        const cleanedValue = value.replace(/[^\d]/g, '');

        // Verifica se o valor é um número válido
        const numberValue = parseFloat(cleanedValue);

        // Se não for um número, retorna uma string vazia ou algum valor padrão
        if (isNaN(numberValue)) {
            return '';
        }

        // Formata o número como moeda
        return new Intl.NumberFormat('pt-BR', {
            style: 'currency',
            currency: 'BRL'
        }).format(numberValue / 100);
    };

    const parseCurrency = (value) => {
        if (!value) return 0; // Retorna 0 se não houver valor

        if (typeof value === 'string') {
            // Remove R$, espaços, e converte vírgulas em pontos
            return parseFloat(value.replace('R$', '').replace(/\./g, '').replace(',', '.').trim());
        }
        return parseFloat(value); // Se já for número, retorna o valor
    };

    const plusFilters = () => {
        return (
            <Grid item container style={{
                gap: '10px',
                width: '100%',
                display: 'flex',
                paddingBottom: '15px',
                backgroundColor: 'white',
                justifyContent: 'space-between',
                flexWrap: screenWidth <= 1260 ? 'wrap' : 'nowrap'
            }}>

                {screenWidth <= 1024 ? <>
                    <Grid item sx={styles.gridInput}>
                        <OutlinedInput
                            sx={styles.customInputNumber}
                            type="text"
                            placeholder="Valor venda (início)"
                            value={formatCurrency(valorVendaInicio)}
                            onChange={handleValorVendaInicioChange}
                        />
                    </Grid>

                    <Grid item sx={styles.gridInput}>
                        <OutlinedInput
                            sx={styles.customInputNumber}
                            type="text"
                            placeholder="Valor venda (fim)"
                            value={formatCurrency(valorVendaFim)}
                            onChange={handleValorVendaFimChange}
                        />
                    </Grid>
                </> : null}

                <Grid item sx={screenWidth >= 1260 ? styles.gridInputSmallScreen : styles.gridInput}>
                    <OutlinedInput
                        sx={styles.customInputNumber}
                        type="text"
                        placeholder="Código de autorização"
                        value={codigoAutorizacao}
                        onChange={handleCodigoAutorizacaoChange}
                    />
                </Grid>

                <Grid item sx={screenWidth >= 1260 ? styles.gridInputSmallScreen : styles.gridInput}>
                    <OutlinedInput
                        sx={styles.customInputNumber}
                        type="text"
                        placeholder="#ID"
                        value={id}
                        onChange={handleIdChange}
                    />
                </Grid>

                <Grid item sx={screenWidth >= 1260 ? styles.gridInputSmallScreen : styles.gridInput}>
                    <TextField
                        select
                        variant="outlined"
                        sx={styles.customInputNumber}
                        value={selectStatus}
                        onChange={handleStatusChange}
                        SelectProps={{
                            native: true,
                        }}
                    >
                        <option value="Status">Status</option>
                        <option value="Concluída">Concluída</option>
                        <option value="Pendente">Pendente</option>
                    </TextField>
                </Grid>

                {/* <Grid item sx={styles.gridInput}>
                    <InputMask
                        maskChar={null}
                        mask={"**********"}
                    >
                        {() => (
                            <TextField
                                placeholder="NSU/DOC"
                                type="text"
                                sx={styles.customInputNumber}
                            />
                        )}
                    </InputMask>
                </Grid> */}

                {/* <Grid item sx={styles.gridInput}>
                    <InputMask
                        maskChar={null}
                        mask={"9999"}
                    >
                        {() => (
                            <TextField
                                placeholder="4 últimos dígitos cartão"
                                type="text"
                                sx={styles.customInputNumber}
                            />
                        )}
                    </InputMask>
                </Grid> */}

                {/* <Grid item
                    sx={{
                        width: isMobile ? '10%' : menuOpen && screenWidth <= 900 ? '10%' : screenWidth <= 1400 ? '5%' : '4%',
                        height: '45px',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        border: '1px solid #FFFFFF',
                        borderRadius: '15px',
                        bgcolor: '#FFFFFF'
                    }}>

                </Grid> */}

            </Grid>
        );
    };

    const [bigValues, setBigValues] = useState([
        { label: 'Crédito parcelado', value: 'R$ 0,00' },
        { label: 'Crédito à vista', value: 'R$ 0,00' },
        { label: 'Débito', value: 'R$ 0,00' },
        { label: 'Pix', value: 'R$ 0,00' },
        { label: 'Xulis', value: 'R$ 0,00' }
    ]);

    const calculateTotalBruto = (rows) => {
        return rows.reduce((total, item) => {
            const valorBruto = parseFloat(item.valorbruto);
            return total + (isNaN(valorBruto) ? 0 : valorBruto);
        }, 0);
    };

    useEffect(() => {
        const totalBruto = calculateTotalBruto(filteredRows);
        setVBruto(totalBruto);
    }, [filteredRows]);

    const calculateTotalLiquido = (rows) => {
        return rows.reduce((total, item) => {
            const valor_liquido = parseFloat(item.valor_liquido);
            return total + (isNaN(valor_liquido) ? 0 : valor_liquido);
        }, 0);
    };

    useEffect(() => {
        const totalLiquido = calculateTotalLiquido(filteredRows);
        setVLiquido(totalLiquido);
    }, [filteredRows]);

    const calculateTotalByPaymentType = (rows, paymentType) => {
        return rows
            .filter(item => item.forma_pagamento === paymentType)
            .reduce((total, item) => {
                const valorBruto = parseFloat(item.valorbruto);
                return total + (isNaN(valorBruto) ? 0 : valorBruto);
            }, 0);
    };

    const calculateTotalLiquidoByPaymentType = (rows, paymentType) => {
        return rows
            .filter(item => item.forma_pagamento === paymentType)
            .reduce((total, item) => {
                const valorLiquido = parseFloat(item.valor_liquido);
                return total + (isNaN(valorLiquido) ? 0 : valorLiquido);
            }, 0);
    };

    useEffect(() => {
        const totalCreditoParcelado = calculateTotalByPaymentType(filteredRows, 'Crédito parcelado');
        const totalCreditoVista = calculateTotalByPaymentType(filteredRows, 'Crédito à vista');
        const totalDebito = calculateTotalByPaymentType(filteredRows, 'Débito');
        const totalXuliz = calculateTotalByPaymentType(filteredRows, 'Xuliz');
        const totalPix = calculateTotalByPaymentType(filteredRows, 'Pix');

        setBigValues([
            { label: 'Crédito à vista', value: real(totalCreditoVista) },
            { label: 'Crédito parcelado', value: real(totalCreditoParcelado) },
            { label: 'Débito', value: real(totalDebito) },
            { label: 'Pix', value: real(totalPix) },
            { label: 'Xulis', value: real(totalXuliz) }
        ]);

        const totalBruto = totalCreditoParcelado + totalCreditoVista + totalDebito + totalXuliz + totalPix;
        const totalLiquido = calculateTotalLiquidoByPaymentType(filteredRows, 'Crédito parcelado') +
            calculateTotalLiquidoByPaymentType(filteredRows, 'Crédito à vista') +
            calculateTotalLiquidoByPaymentType(filteredRows, 'Débito') +
            calculateTotalLiquidoByPaymentType(filteredRows, 'Xuliz') +
            calculateTotalLiquidoByPaymentType(filteredRows, 'Pix');

        setVBruto(totalBruto);
        setVLiquido(totalLiquido);
    }, [filteredRows]);

    const csvHeaders = [
        { key: 'id', label: 'ID#' },
        { key: 'codigo_autorizacao', label: 'Código de Autorização' },
        { key: 'dataHora', label: 'Data/Hora' },
        { key: 'dataHora', label: 'Previsão de pagamento' },
        { key: 'bandeira', label: 'Bandeira' },
        { key: 'forma_pagamento', label: 'Forma de Pagamento' },
        { key: 'valor_venda', label: 'Valor venda' },
        { key: 'Cashback', label: 'Cashback' },
        { key: 'valor_taxa', label: 'Valor da taxa/tarifa' },
        { key: 'valor_liquido', label: 'Valor líquido' },
        { key: 'status', label: 'Status' },
    ];

    const exportToPDF = () => {
        const doc = new jsPDF();

        doc.autoTable({
            head: [csvHeaders.map(header => header.label)],
            body: rows.map(row => csvHeaders.map(header => row[header.key])),
        });

        doc.save('tabela.pdf');
    };

    return (
        <Grid item xs sx={screenWidth <= 1400 ? styles.contentSmallScreen : isMobile ? styles.contentMobile : styles.content}>
            {isMobile ? null :
                <Grid sx={[screenWidth <= 1400 ? styles.customSeparatorSmallScreen : styles.customSeparator, { flexDirection: screenWidth <= 800 ? 'column' : 'row' }]}>
                    <CustomSeparator menuOption={'Xloja'} item={2} />
                    <div style={{ height: 50, display: "flex", width: "30%", alignItems: 'center', justifyContent: 'flex-end' }}>
                        <span style={{ marginLeft: 10, fontSize: 20, marginLeft: 30, fontWeight: "bold" }} id="horas"></span>
                    </div>
                </Grid>
            }
            <Grid container spacing={0} style={{ width: '100%', display: "flex", flexDirection: 'column', paddingBottom: '25px', justifyContent: 'space-between' }}>

                <Grid style={{
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'space-between',
                    flexDirection: 'row',
                    paddingBottom: '15px'
                }}>

                    <Grid item container style={{
                        gap: '10px',
                        width: '95%',
                        display: 'flex',
                        justifyContent: 'space-between',
                        backgroundColor: 'white',
                        flexWrap: screenWidth <= 1260 ? 'wrap' : 'nowrap',
                    }}>

                        <Grid item sx={styles.gridInput}>
                            <DatePicker
                                wrapperClassName="datePicker"
                                selected={startDate}
                                onChange={(dates) => handleDateChange(dates)}
                                startDate={startDate}
                                endDate={endDate}
                                selectsRange
                                locale="pt"
                                dateFormat="dd/MM/yyyy"
                                customInput={
                                    <InputMask
                                        style={{ width: '100%' }}
                                        placeholder="Período"
                                        type="text"
                                        mask="99/99/9999 - 99/99/9999"
                                        value={startDate && endDate
                                            ? `${startDate.toLocaleDateString()} - ${endDate.toLocaleDateString()}`
                                            : startDate
                                                ? startDate.toLocaleDateString()
                                                : ''}
                                    >
                                        {() => (
                                            <TextField
                                                onClick={toggleCalendar}
                                                placeholder="Período"
                                                sx={{ ...styles.customInputNumber }}
                                            />
                                        )}
                                    </InputMask>
                                }
                            />
                        </Grid>

                        <Grid item sx={styles.gridInput}>
                            <TextField
                                select
                                variant="outlined"
                                sx={styles.customInputNumber}
                                value={selectedBandeira}
                                onChange={handleBandeiraChange}
                                SelectProps={{ native: true }}
                            >
                                <option value="bandeira">Bandeira</option>
                                <option value="Xuliz">Xulis</option>
                                <option value="Visa">Visa</option>
                                <option value="Master">Master</option>
                                <option value="Elo">Elo</option>
                                <option value="Pix">Pix</option>
                                <option value="Hipercard">Hipercard</option>
                                <option value="Amex">Amex</option>
                            </TextField>
                        </Grid>

                        <Grid item sx={styles.gridInput}>
                            <TextField
                                select
                                variant="outlined"
                                sx={styles.customInputNumber}
                                value={selectedFormaPagamento}
                                onChange={handleFormaPagamentoChange}
                                SelectProps={{ native: true }}
                            >
                                <option value="formaPagamento">Forma de pagamento</option>
                                <option value="Crédito à vista">Crédito à vista</option>
                                <option value="Crédito parcelado">Crédito parcelado</option>
                                <option value="Débito">Débito</option>
                                <option value="Pix">Pix</option>
                                <option value="Xuliz">Xulis</option>
                            </TextField>
                        </Grid>

                        {screenWidth <= 1024 ? null : <>
                            <Grid item sx={styles.gridInput}>
                                <OutlinedInput
                                    sx={styles.customInputNumber}
                                    type="text"
                                    placeholder="Valor venda (início)"
                                    value={formatCurrency(valorVendaInicio)}
                                    onChange={handleValorVendaInicioChange}
                                />
                            </Grid>

                            <Grid item sx={styles.gridInput}>
                                <OutlinedInput
                                    sx={styles.customInputNumber}
                                    type="text"
                                    placeholder="Valor venda (fim)"
                                    value={formatCurrency(valorVendaFim)}
                                    onChange={handleValorVendaFimChange}
                                />
                            </Grid>
                        </>}

                    </Grid>

                    <Grid item
                        sx={{
                            width: '4%',
                            height: '45px',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            border: '1px solid #2F4650',
                            borderRadius: '15px',
                            bgcolor: '#142A30'
                        }}>
                        <FontAwesomeIcon
                            icon={plusOnOff ? faMinus : faPlus}
                            style={{ fontSize: '16px', color: "#9DE200", cursor: 'pointer' }}
                            onClick={() => setPlusOnOff(!plusOnOff)}
                        />
                    </Grid>
                </Grid>
                {plusOnOff ?
                    <Grid item container style={{
                        gap: '10px',
                        width: '95%',
                        display: 'flex',
                        justifyContent: 'space-between',
                        backgroundColor: 'white',
                        flexWrap: screenWidth <= 1260 ? 'wrap' : 'nowrap',
                    }}>
                        {plusFilters()}
                        {screenWidth >= 1260 ? null : <Grid item
                            sx={{
                                width: isMobile ? '10%' : menuOpen && screenWidth <= 900 ? '10%' : screenWidth <= 1400 ? '5%' : '4%',
                                height: '45px',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                border: '1px solid #FFFFFF',
                                borderRadius: '15px',
                                bgcolor: '#FFFFFF'
                            }}>

                        </Grid>}
                    </Grid> : null}

                <Grid sx={{ width: '100%', bgcolor: '#F0EFEB', marginBottom: '15px', borderRadius: '15px' }}>

                    <Grid sx={{
                        padding: '19px 35px',
                        display: 'flex',
                        flexDirection: menuOpen && screenWidth <= 900 ? 'column' : screenWidth <= 800 ? 'column' : 'row',
                        justifyContent: 'space-between',
                        alignItems: screenWidth <= 800 ? 'center' : null,
                        alignItems: 'center'
                    }}>

                        <Grid sx={{
                            display: 'flex',
                            width: menuOpen && screenWidth <= 900 ? '100%' : screenWidth <= 800 ? '100%' : '30%',
                            justifyContent: 'space-between',
                            flexDirection: screenWidth < 1630 ? 'column' : 'row',
                            alignItems: 'center',
                        }}>
                            <Typography sx={styles.bigPrices}>
                                Quantidade de vendas
                            </Typography>
                            <Typography sx={{ fontSize: '24px', fontWeight: 700 }}>
                                {qVendas}
                            </Typography>
                        </Grid>
                        {menuOpen && screenWidth <= 900 ? null : screenWidth < 800 ? null :
                            <Grid sx={{ height: '14px', borderRight: '2px solid #000' }} />
                        }

                        <Grid sx={{
                            display: 'flex',
                            width: screenWidth <= 800 ? '100%' : '30%',
                            justifyContent: 'space-between',
                            flexDirection: screenWidth < 1630 ? 'column' : 'row',
                            alignItems: 'center',
                        }}>
                            <Typography sx={styles.bigPrices}>
                                Valor bruto
                            </Typography>
                            <Typography sx={{ fontSize: '24px', fontWeight: 700 }}>
                                {real(vBruto)}
                            </Typography>
                        </Grid>
                        {menuOpen && screenWidth <= 900 ? null : screenWidth < 800 ? null :
                            <Grid sx={{ height: '14px', borderRight: '2px solid #000' }} />
                        }

                        <Grid sx={{
                            display: 'flex',
                            width: screenWidth <= 800 ? '100%' : '30%',
                            justifyContent: 'space-between',
                            flexDirection: screenWidth < 1630 ? 'column' : 'row',
                            alignItems: 'center'
                        }}>
                            <Typography sx={styles.bigPrices}>
                                Valor líquido
                            </Typography>
                            <Typography sx={{ fontSize: '24px', fontWeight: 700 }}>
                                {real(vLiquido)}
                            </Typography>
                        </Grid>

                    </Grid>

                </Grid>

                <Grid container sx={{
                    width: '100%',
                    marginBottom: '35px',
                    display: 'flex',
                    flexWrap: screenWidth <= 1740 ? 'wrap' : 'nowrap',
                    gap: '10px'
                }}>
                    <Grid item sx={{
                        width: '100%',
                        flexWrap: screenWidth <= 1600 ? 'wrap' : 'wrap',
                        gap: isMobile ? '5px' : '10px',
                        display: 'flex',
                        paddingBottom: '10px',
                        justifyContent: 'space-between'
                    }}>
                        {bigValues.map((i) => (
                            <Grid sx={{
                                // width: menuOpen ? '48%' : isMobile ? '49%' : screenWidth <= 1600 ? '32%' : '25%',
                                height: '114px',
                                display: 'flex',
                                borderRadius: '15px',
                                padding: '30px 0px 30px 30px',
                                flexDirection: 'column',
                                alignItems: 'flex-start',
                                border: '1px solid #999999',

                                flexGrow: 1,
                                flexShrink: 1,
                                flexBasis: '25%',
                                minWidth: '295px',
                                maxWidth: '100%',
                            }}>
                                <Typography sx={{ color: '#999999' }}>
                                    {i.label}
                                </Typography>
                                <Typography sx={{ fontSize: '24px', fontWeight: 700 }}>
                                    {i.value}
                                </Typography>
                            </Grid>
                        ))}
                        <Grid item sx={{
                            width: menuOpen ? '48%' : isMobile ? '49%' : screenWidth <= 1600 ? '32%' : '35%',
                            // width: screenWidth <= 1740 ? '100%' : '15%',
                            display: 'flex',
                            flexWrap: 'wrap',

                            flexGrow: 1,
                            flexShrink: 1,
                            flexBasis: '25%',
                            minWidth: '295px',
                            maxWidth: '100%'
                            // flexWrap: screenWidth <= 1740 ? 'nowrap' : 'wrap',
                            // gap: screenWidth <= 1740 ? '10px' : null,
                        }}>
                            <Button onClick={() => exportToPDF()} sx={{
                                width: '100%',
                                // width: screenWidth <= 1740 ? '50%' : '100%',
                                height: '52px',
                                display: 'flex',
                                textDecoration: 'none',
                                textTransform: 'none',
                                borderRadius: '15px',
                                alignItems: 'center',
                                marginBottom: '10px',
                                padding: '15.6px 17px',
                                border: '1px solid #262626',
                                justifyContent: 'space-between',
                            }}>
                                <Typography sx={{ fontSize: '15px', fontWeight: 700, color: '#262626' }}>
                                    <FontAwesomeIcon
                                        icon={faPrint}
                                        style={{ fontSize: '16px', color: "#262626", paddingRight: '20px' }}
                                    />
                                    Imprimir
                                </Typography>
                                <img src={arrowRigthButtonBlack} style={{ width: "14px", height: "14px" }} alt="Usuário" />
                            </Button>
                            <Button onClick={() => setOpen(!open)} sx={{
                                width: '100%',
                                // width: screenWidth <= 1740 ? '50%' : '100%',
                                height: '52px',
                                display: 'flex',
                                textDecoration: 'none',
                                textTransform: 'none',
                                bgcolor: '#17333A',
                                borderRadius: '15px',
                                alignItems: 'center',
                                marginBottom: '10px',
                                padding: '15.6px 17px',
                                border: '1px solid #17333A',
                                justifyContent: 'space-between',
                                '&:hover': {
                                    backgroundColor: '#17333A'
                                },
                                '&:focus': {
                                    backgroundColor: '#17333A'
                                },
                                '&:active': {
                                    backgroundColor: '#17333A'
                                }
                            }}>
                                <Typography sx={{ fontSize: '15px', fontWeight: 700, color: '#FFFFFF' }}>
                                    <FontAwesomeIcon
                                        icon={faArrowUpRightFromSquare}
                                        style={{ fontSize: '16px', color: "#FFFFFF", paddingRight: '20px' }}
                                    />
                                    Exportar
                                </Typography>
                                <img src={arrowRigthButtonWith} style={{ width: "14px", height: "14px" }} alt="Usuário" />
                            </Button>
                        </Grid>
                    </Grid>

                </Grid>

                <div style={{ width: '100%', flexDirection: 'column', display: "flex" }}>

                    <Tabela
                        rows={filteredRows}
                    />

                </div>

            </Grid>

            <ModalExport rows={filteredRows} open={open} setOpen={setOpen} isMobile={isMobile} />

        </Grid >
    );
};

export default Minhasvendas;

const styles = Stylesheet.create({
    content: {
        margin: '40px 50px 16px 70px'
    },
    contentSmallScreen: {
        margin: '30px 25px'
    },
    contentMobile: {
        margin: '0px 10px'
    },
    customSeparator: {
        alignItems: 'center',
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: '43px'
    },
    customSeparatorSmallScreen: {
        alignItems: 'center',
        display: 'flex',
        justifyContent: 'space-around',
        marginBottom: '20px'
    },
    gridInput: {
        flexGrow: 1,
        flexShrink: 1,
        flexBasis: '30%',
        maxWidth: '100%',
        minWidth: '150px',
    },
    gridInputSmallScreen: {
        flexGrow: 1,
        flexShrink: 1,
        flexBasis: '50%',
        maxWidth: '100%',
        minWidth: '150px',
    },
    customInputNumber: {
        height: '45px',
        borderRadius: '15px',
        border: '1px solid #2F4650',
        display: 'flex',
        justifyContent: 'center',
        '& .MuiOutlinedInput-notchedOutline': {
            border: 'none',
        },
        '& .MuiInputBase-input': {
            fontSize: '15px',
            color: '#142A30',
            fontWeight: 'bold',
            padding: '15px 17px',
        },
        '& .MuiInputBase-input::placeholder': {
            opacity: 1,
            color: '#142A30',
            fontWeight: 'bold',
        },
        '& input[type=number]::-webkit-outer-spin-button, & input[type=number]::-webkit-inner-spin-button': {
            WebkitAppearance: 'none',
            margin: 0,
        },
    },
    bigPrices: {
        fontSize: '20px',
        fontWeight: 700,
        color: '#999999',
    },
    modal: {
        width: '100%',
        maxWidth: '1100px',
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        bgcolor: '#FFFFFF',
        borderRadius: '10px',
        boxShadow: 24,
        p: '65px 78px',
    },
    titleModal: {
        fontSize: '24px',
        fontWeight: 600
    },
    subTitleModal: {
        fontSize: '15px',
        fontWeight: 400
    },
    radioButton: {
        width: '100%',
        maxWidth: '280px',
        fontWeight: 500,
        color: '#3D3D3D',
        textTransform: 'none',
        backgroundColor: '#F1F1F1',
        fontSize: '20px',
        fontWeight: 600,
        border: 'none',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        '&.Mui-selected': {
            border: 'none',
            fontWeight: 600,
            borderRadius: '10px',
            backgroundColor: '#F2E2C4',
            '&:focus': {
                backgroundColor: '#F2E2C4',
                borderRadius: '10px',
            },
            '&:active': {
                backgroundColor: '#F2E2C4',
                borderRadius: '10px',
            }
        },
        '&:not(.Mui-selected)': {
            border: '0px solid #F2E2C4',
            borderRadius: '10px',
        },
    }
});