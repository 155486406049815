import { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import Menu from "./componentes/menu";
import MenuMobile from "./componentes/menuMobile";


import { useEffect } from "react";
import xloja_12 from './assets/xloja_12.png'; // Tell webpack this JS file uses this image
import waiting from './assets/waiting.jpg';
import xloja from './assets/xloja.png'; // Tell webpack this JS file uses this image
import vecto2123 from './assets/vecto2123.png'; // Tell webpack this JS file uses this image
import aprovado_status from './assets/aprovaod_status.png'; // Tell webpack this JS file uses this image
import agendado_status from './assets/status_agendado.png'; // Tell webpack this JS file uses this image

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHouse, faShoppingBag, faChartPie, faBox, faCircleDollarToSlot, faBullhorn, faQuestionCircle, faUsers, faGear, faSquareXmark, faMobile, faCalendar, faChevronDown, faBookReader, faArrowLeft } from '@fortawesome/free-solid-svg-icons'
import { useState } from "react";

import hipercard from './assets/hipercard.png';
import amex from './assets/amex.png';
import visa from './assets/visa.png';
import master from './assets/master.png';
import elo from './assets/elo_debito.png';
import flagXulis from './assets/flags/flagXulis.png';

import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import logo1 from './assets/xuliz_logo2.png'; // Tell webpack this JS file uses this image

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import * as XLSX from 'xlsx';
import { Button } from "react-bootstrap";


import { saveAs } from 'file-saver';


import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import CustomSeparator from "./componentes/Breadcrumb";
import styles from "./DetalhesVendas.module.css"
import DetalhesVendas from "./DetalhesVendas/DetalhesVendas";

pdfMake.vfs = pdfFonts.pdfMake.vfs;

const TelaXloja01Areaparceiros = () => {
  const navigate = useNavigate();

  const [emailRef, setEmailRef] = useState('');
  const [value, setValue] = useState('');
  const [urlfotoperfil, setFotoUrl] = useState('');
  const [cashbackConta, setCashback] = useState('3');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(50);



  const [canal_venda, setCanalVenda] = useState('-');


  const [dadosVenda, setDadosVenda] = useState([]);

  const [dadosX, setDadosX] = useState([]);
  const [rows, setRow] = useState([]);
  const [tokenEsta, setTokenEstabelecimento] = useState('');
  const [totalTarifa, setTotalTarifa] = useState('');
  const [valorCashback, setValorCashback] = useState('');
  const [dataTransacao, setDataTransacao] = useState('');
  const [horaTransacao, setHoraTransacao] = useState('');
  const [codigoAutorizacao, setCodigoAutorizacao] = useState('');
  const [vendastatus, setVendaStatus] = useState('');

  const [idestabelecimento, setIdEstabelecimento] = useState('')


  const [totalVenda, setTotalVenda] = useState('');

  const [quantidadeVendas, setQuantidadeVendas] = useState('0');
  const [valorTotal, setValorTotal] = useState('0');
  const [valorLiquido, setValorLiquido] = useState('0');
  const [valorLiquidoTotal, setValorLiquidoTotal] = useState('0');


  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [showCalendar, setShowCalendar] = useState(false);

  const [vendaInicio, setVendaInicio] = useState('');
  const [vendaFinal, setVendaFinal] = useState('');

  const [screenWidth, setScreenWidth] = useState(null);
  const [isMobile, setIsMobile] = useState(false);
  const [forma_pagamento, setFormaPagamento] = useState('');

  const [bandeira, setBandeira] = useState('');


  const [transactionsData, setTransacoes] = useState([
  ]);

  const [parcelamentosData, setParcelamentos] = useState([
  ]);


  const [jaImpresso, setJaImpresso] = useState('');
  const [menuOpen, setMenuOpen] = useState(true);


  useEffect(() => {
    updateDimensions();
    window.addEventListener('resize', updateDimensions);

    return () => {
      window.removeEventListener('resize', updateDimensions);
    };
  }, []);


  const updateDimensions = () => {
    setIsMobile(window.innerWidth <= 800); // Defina o limite de largura para o modo mobile conforme necessário
    verificarNiveisLogin(dadosX)
    setScreenWidth(window.innerWidth);

    if (window.innerWidth <= 1400 && menuOpen) {
      setMenuOpen(false)
    }
    if (window.innerWidth > 1400 && menuOpen) {
      setMenuOpen(true)
    }
  };




  function getParameterByName(name, url) {
    if (!url) url = window.location.href;
    name = name.replace(/[\[\]]/g, '\\$&');
    var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
      results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, ' '));
  }


  const handleDownload = () => {

    // console.log(rows)

    var dados = [];



    for (var x = 0; x < rows.length; x++) {

      var linha = rows[x].id + ' - ' + rows[x].cashback + ' - ' + rows[x].data_hora + ' - ' + rows[x].valor_venda + ' - ' + rows[x].status + ' - ' + rows[x].usuario
      dados.push(linha)
    }


    var data = dados

    const docDefinition = {
      content: [
        { text: 'Minhas vendas - Xloja', fontSize: 20, bold: true },
        { text: '\n' },
        ...data.map((item, index) => ({ text: item, fontSize: 9, margin: 5 })),
      ],
    };

    const pdfDocGenerator = pdfMake.createPdf(docDefinition);
    pdfDocGenerator.getBlob((blob) => {
      saveAs(blob, 'minhas_vendas.pdf');
    });
  };


  const handleDateChange = (dates) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
  };



  const clickAplicar = () => {

    setShowCalendar(false)
    VerificaChamar(endDate, startDate)
  }

  const toggleCalendar = () => {


    setShowCalendar(true)


    return false;

  };





  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;





  useEffect(() => {
    setarHora()

    obterDados()
  }, []);

  const Swal = require('sweetalert2')






  const getTokenEstabelciment = async (token) => {
    const form = new FormData();
    form.append("idempresa", "1350");
    form.append("token", "e84d7ad2-f0cc-11eb-b838-568a76eb275e");
    form.append("metodo", "ListaEstabelecimentos");


    const options = {
      method: 'POST',
    };

    options.body = form;

    var rres = await fetch('https://xuliz.com.br/painel/integracao/estabelecimento.php', options).then(response => response.json());

    var arrrayEstabelecimnto = rres.estabelecimentos;

    var tokenEstabelecimento = '';
    for (var x = 0; x < arrrayEstabelecimnto.length; x++) {
      if (arrrayEstabelecimnto[x].idestabelecimento == token) {
        tokenEstabelecimento = arrrayEstabelecimnto[x].token;
        break;
      }
    }

    //  console.log(rres)
    //  console.log(tokenEstabelecimento)
    setTokenEstabelecimento(tokenEstabelecimento)
    return tokenEstabelecimento;


  }

  const obterDados = async () => {
    var data = getParameterByName('data');
    var id = getParameterByName('id');

    var dados = await localStorage.getItem('login')
    dados = JSON.parse(dados);
    var idestabele = dados.idestabelecimento;

    setIdEstabelecimento(id)


    var getTokenEstabelecimneto = await getTokenEstabelciment(idestabele);

    if (getTokenEstabelecimneto == null) {
      getTokenEstabelecimneto = dados.tokenestabelecimento;
    }

    data = data.split('/');
    data = data[2] + '-' + data[1] + '-' + data[0]


    const options = {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: new URLSearchParams({
        idempresa: '1350',
        token: getTokenEstabelecimneto,
        idvendaxuliz: '',
        metodo: 'Lista',
        idestabelecimento: idestabele,
        id: id,
        // datainicial: data,
        // datafinal: data,
      })
    };

    // fetch('https://xuliz.com.br/painel/integracao/vendaxuliz.php', options)
    fetch('https://www.xulizsistemas.com.br/retaguarda/integracao/venda/listarvendageral.php', options)
      .then(response => response.json())
      .then(response => tradarDados(response, id))
      .catch(err => console.error(err));

  }

  const setarHora = async () => {
    localStorage.setItem('setValorFinalFloat', '');
    localStorage.setItem('setValorInicialFloat', '');

    var getDa = await getData();
    document.getElementById("horas").innerHTML = getDa;
  }

  const getData = async () => {
    var today = new Date();
    var day = today.getDate() + "";
    var month = (today.getMonth() + 1) + "";
    var year = today.getFullYear() + "";
    var hour = today.getHours() + "";
    var minutes = today.getMinutes() + "";

    day = checkZero(day);
    month = checkZero(month);
    year = checkZero(year);
    hour = checkZero(hour);
    minutes = checkZero(minutes);

    var data = day + "/" + month + "/" + year + " " + hour + ":" + minutes;

    return data;
    function checkZero(data) {
      if (data.length == 1) {
        data = "0" + data;
      }
      return data;
    }
  }

  const tradarDados = async (dadosG, id) => {

    // console.log('dadosG', dadosG)

    var array_compras = dadosG.retorno;

    // console.log('dadosG', array_compras)

    for (var x = 0; x < array_compras.length; x++) {
      var id_get = array_compras[x].id
      if (id_get == id) {

        var getValorLiquido = array_compras[x].valorliquido
        getValorLiquido = getValorLiquido.replace("-", "")


        array_compras[x].valorliquido = getValorLiquido

        setDadosVenda(array_compras[x])
        realizarCalculos(array_compras[x])
        break;
      }
    }


  }

  const realizarCalculos = async (dados) => {
    // console.log('dadosCalculos', dados)


    var detalhes = dados.vendaparcela;
    var getBandeira = dados.bandeira
    var codigo = dados.codigoautorizacao

    //previsao
    var dia = dados.datavencimento;
    dia = dia.split(' ');
    dia = dia[0];
    dia = dia.split('-');
    dia = dia[2] + '/' + dia[1] + '/' + dia[0]



    // var totalJuros = 0;
    var valorLiquido = 0;


    var parcelados = []
    var transacoes = [dados]


    if (dados.online == 'N') {
      setCanalVenda('Maquininha')
    } else {
      setCanalVenda('Aplicativo')
    }

    var valor_venda_liquido = 0;

    // console.log('dados', dados)

    if (dados.bandeira == 'Xuliz') {
      parcelados.push(
        {
          bandeira: dados.bandeira,
          valor_venda: 'R$ ' + dados.valorbruto,
          idvenda: '' + dados.id,
          datavencimento: dados.datavencimento,
          valorbruto: formatCurrency(dados.valorbruto),
          valor_venda_liquido: formatCurrency(dados.valorliquido),
          valorliquido: formatCurrency(dados.valorliquido),
          vendastatus: dados.status,
          numerocartao: dados.numerocartao,
          online: dados.online,
          codigoautorizacao: dados.codigoautorizacao
        }
      )
    }

    for (var x = 0; x < detalhes.length; x++) {

      var dataVencimentoRef = detalhes[x].datavencimento;
      dataVencimentoRef = dataVencimentoRef.split('-')
      dataVencimentoRef = dataVencimentoRef[2] + '/' + dataVencimentoRef[1] + '/' + dataVencimentoRef[0]

      var getLiquido = detalhes[x].valorliquido;
      getLiquido = getLiquido.replace('-', '')

      valor_venda_liquido += parseFloat(detalhes[x].valorliquido) || 0;

      setValorLiquidoTotal(formatReal(valor_venda_liquido));

      getLiquido = formatCurrency(getLiquido)

      parcelados.push(
        {
          bandeira: dados.bandeira,
          valor_venda: 'R$ ' + dados.valorbruto,
          idvenda: '' + dados.id,
          datavencimento: dataVencimentoRef,
          valorbruto: formatCurrency(detalhes[x].valorbruto),
          valor_venda_liquido: valor_venda_liquido,
          valorliquido: getLiquido,
          vendastatus: detalhes[x].status,
          numerocartao: dados.numerocartao,
          online: dados.online,
          codigoautorizacao: dados.codigoautorizacao
        }
      )

    }



    if (getBandeira == 'Master Débito' || getBandeira == 'Master Crédito') {

      setBandeira(getBandeira)
    }
    if (getBandeira == 'Visa Débito' || getBandeira == 'Visa Crédito') {

      setBandeira(getBandeira)
    }
    if (getBandeira == 'Elo Débito' || getBandeira == 'Elo Crédito') {

      setBandeira(getBandeira)
    }
    if (getBandeira == 'XULIZ' || getBandeira == 'Xuliz') {

      setBandeira(getBandeira)
    }

    if (getBandeira == 'PIX' || getBandeira == 'Pix') {
      setBandeira(getBandeira)
    }

    var parcelas = dados.vendaparcela
    var totalparcelas = 0;

    if (parcelas.length > 0) {
      totalparcelas = parcelas.length;

    }


    if (totalparcelas == undefined) {
      totalparcelas = "1"
    }

    // console.log('transacoes', transacoes)
    // console.log('parcelados', parcelados)


    setTransacoes(transacoes)
    setParcelamentos(parcelados)
    setFormaPagamento(getBandeira)
    var taxa = dados.valortaxa
    taxa = parseFloat(taxa)
    taxa = formatReal(taxa)

    var valorLiquido = dados.valorliquido
    valorLiquido = parseFloat(valorLiquido)
    valorLiquido = formatReal(valorLiquido)

    var valorbruto = '' + dados.valorbruto;
    valorbruto = parseFloat(valorbruto)
    valorbruto = formatReal(valorbruto)

    var getValorTotal = '' + dados.total;
    getValorTotal = parseFloat(getValorTotal)
    getValorTotal = formatReal(getValorTotal)

    var cashback = '' + dados.valorcashback;
    cashback = parseFloat(cashback)
    cashback = formatReal(cashback)


    setTotalVenda(getValorTotal)
    setTotalTarifa(taxa)
    setValorCashback(cashback)
    setValorLiquido(valorLiquido)
    setVendaStatus(dados.status)



    var dia = dados.data;
    dia = dia.split(' ');


    var horas = dia[1]
    horas = horas.split(':')
    horas = horas[0] + ':' + horas[1]

    dia = dia[0];
    dia = dia.split('-');

    dia = dia[2] + '/' + dia[1] + '/' + dia[0]

    setCodigoAutorizacao(codigo)
    setDataTransacao(dia)
    setHoraTransacao(horas)


    //     if (!jaImpresso) {
    //     }
    //     var Impresso = <Transacoes />; 

    // setJaImpresso(Impresso);


  }

  function formatReal(valor) {
    let numero = valor.toFixed(2).split('.');
    numero[0] = "" + numero[0].split(/(?=(?:...)*$)/).join('.');
    return numero.join(',');
  }



  const statusImagemAprovacao = (data, as) => {
    var getData = data.split("/");


    var today = new Date();

    var mes = '' + getData[1];
    mes = mes - 1;

    var dataComparar = new Date(getData[2], mes, getData[0]);

    if (dataComparar > today) {
      return agendado_status
    }



    return aprovado_status
  }


  const Parcelamentos = () => {

    return (
      <>
        {parcelamentosData.map((parcelamento, index) => (

          <>
            {/* <div style={{ width:'100%', height:114, flexDirection: 'row', display: 'flex',backgroundColor:'#f1f1f1',marginBottom:4,paddingLeft:35}}> */}

            <div style={{ width: '100%', minHeight: 114, height: 114, display: 'flex', backgroundColor: '#f1f1f1', marginBottom: 4 }}>
              <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', width: '20%', marginLeft: 50 }}>
                <span style={{ fontWeight: 600, fontSize: 15 }}>Parcelado {index + 1}/{parcelamentosData.length}</span>
                <span style={{ fontWeight: 400, fontSize: 15, paddingTop: 10 }}>{index + 1}ª  Parcelada</span>
              </div>

              <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', width: '20%' }}>
                <span style={{ fontWeight: 600, fontSize: 15 }}>Previsão de pagamento</span>
                <span style={{ fontWeight: 400, fontSize: 15, paddingTop: 10 }}>{parcelamento.datavencimento}</span>
              </div>
              <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', width: '20%' }}>
                <span style={{ fontWeight: 600, fontSize: 15 }}>Valor bruto</span>
                <span style={{ fontWeight: 400, fontSize: 15, paddingTop: 10 }}>{formatCurrency(parcelamento.valorbruto)}</span>
              </div>

              <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', width: '20%' }}>
                <span style={{ fontWeight: 600, fontSize: 15 }}>Valor líquido</span>
                <span style={{ fontWeight: 400, fontSize: 15, paddingTop: 10 }}>{parcelamento.valorliquido}</span>
              </div>

              <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', width: '20%' }}>
                <span style={{ fontWeight: 600, fontSize: 15 }}>Status da liquidação</span>
                <img src={statusImagemAprovacao(parcelamento.datavencimento, parcelamento)} style={{ width: 77, height: 25, paddingTop: 10 }} />

              </div>

            </div>

          </>
        ))}
      </>
    )
  }



  const Transacoes = () => {

    return (
      <>


        {transactionsData.map((transaction, index) => (
          <>


            <div style={{ width: '100%', height: 114, flexDirection: 'row', display: 'flex', backgroundColor: '#f1f1f1', marginBottom: 4, paddingLeft: 35 }}>

              {/* <div style={{width:'14.3%',display:'flex',justifyContent:'center',flexDirection:'column',marginLeft:10,marginRight:10}}>
                        <span style={{fontSize:15,fontWeight:400,color:'#3D3D3D'}}>Número do cartão</span>
                        <span style={{fontSize:15,fontWeight:700,color:'gray'}}>{transaction.numero_cartao}</span>

                    </div>

                    <div style={{width:'14.3%',display:'flex',justifyContent:'center',flexDirection:'column',marginLeft:10,marginRight:10}}>
                        <span style={{fontSize:15,fontWeight:400,color:'#3D3D3D'}}>Número da maquininha</span>
                        <span style={{fontSize:15,fontWeight:700,color:'gray'}}>{transaction.numero_maquininha}</span>

                    </div> */}

              {/* <div style={{width:'14.3%',display:'flex',justifyContent:'center',flexDirection:'column',marginLeft:10,marginRight:10}}>
                        <span style={{fontSize:15,fontWeight:400,color:'#3D3D3D'}}>Tipo</span>
                        <span style={{fontSize:15,fontWeight:700,color:'gray'}}>{transaction.tipo}</span>

                    </div> */}

              {/* <div style={{width:'14.3%',display:'flex',justifyContent:'center',flexDirection:'column',marginLeft:10,marginRight:10}}>
                        <span style={{fontSize:15,fontWeight:400,color:'#3D3D3D'}}>NSU/DOC</span>
                        <span style={{fontSize:15,fontWeight:700,color:'gray'}}>{transaction.nsu}</span>

                    </div> */}

              {/* <div style={{width:'14.3%',display:'flex',justifyContent:'center',flexDirection:'column',marginLeft:10,marginRight:10}}>
                        <span style={{fontSize:15,fontWeight:400,color:'#3D3D3D'}}>Canal de venda</span>
                        <span style={{fontSize:15,fontWeight:700,color:'gray'}}>{canal_venda}</span>

                    </div> */}


              {/* <div style={{width:'14.3%',display:'flex',justifyContent:'center',flexDirection:'column',marginLeft:10,marginRight:10}}>
                        <span style={{fontSize:15,fontWeight:400,color:'#3D3D3D'}}>Id venda</span>
                        <span style={{fontSize:15,fontWeight:700,color:'gray'}}>{transaction.idvenda}</span>

                    </div> */}

              {/* <div style={{width:'14.3%',display:'flex',justifyContent:'center',flexDirection:'column',marginLeft:10,marginRight:10}}>
                        <span style={{fontSize:15,fontWeight:400,color:'#3D3D3D'}}>Cod.Autorização</span>
                        <span style={{fontSize:15,fontWeight:700,color:'gray'}}>{transaction.codigoautorizacao}</span>

                    </div> */}
            </div>
          </>
        ))}


      </>
    )
  }


  function sleep(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  const verDetalhes = async () => {
    alert('asd')
  }

  const getDados = async () => {
    var dados = await localStorage.getItem('login')

    if (dados == null) {
      navigate("/");

      return false;
    }
    dados = JSON.parse(dados)
    setDadosX(dados)
    verificarNiveisLogin(dados)

    //  console.log('dadosX',dados)

    setEmailRef(dados.email)
    //  console.log(dados)


  }

  const verificarNiveisLogin = async (dadosX) => {
    var idperfil = dadosX.idusuariotipo;



    if (idperfil == "7") {
      await sleep(50)

      //financeiro somente relatorios
      document.getElementById('realizar_vendaID').style.display = "none";
      document.getElementById('menu_marketing').style.display = "none";
      document.getElementById('menu_configuracoes').style.display = "none";

    }

    if (idperfil == "8") {
      await sleep(50)

      location.href = "./xloja";

    }

  }



  function sleep(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }



  const formatCurrency = (inputValue) => {
    const rawValue = inputValue.replace(/\D/g, '');

    const formatter = new Intl.NumberFormat('pt-BR', {
      style: 'currency',
      currency: 'BRL',
    });

    return formatter.format(rawValue / 100);
  };


  const bandeiraImages = {
    "Master Débito": master,
    "Master Crédito": master,
    "Visa Débito": visa,
    "Visa Crédito": visa,
    "Elo Débito": elo,
    "Elo Crédito": elo,
    "Amex Débito": amex,
    "Amex Crédito": amex,
    "Hipercard Débito": hipercard,
    "Hipercard Crédito": hipercard,
    "XULIZ": flagXulis,
    "Xuliz": flagXulis,
    // Adicione mais mapeamentos conforme necessário
  };

  if (isMobile == true) {
    return (
      <>
        <MenuMobile page="xloja" />
        <div style={{ height: 150, width: '100%', flexDirection: 'row', display: 'flex', padding: 10 }}>
          <div style={{ width: '100%', display: 'flex', flex: 1, margin: 5, borderRadius: 10, justifyContent: 'center', alignItems: 'center', flexDirection: 'column', backgroundColor: '#F1F1F1' }} id="realizar_vendaID" onClick={() => { location.href = './xloja' }}>
            <FontAwesomeIcon icon={faCircleDollarToSlot} style={{ fontSize: 30, color: "#262626" }} />
            <span style={{ textAlign: 'center', fontSize: 14, marginTop: 10 }}>XLoja</span>
          </div>
          <div style={{ width: '100%', backgroundColor: '#f1f1f1', display: 'flex', flex: 1, margin: 5, borderRadius: 10, justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }} onClick={() => { location.href = './vendashoje' }}>
            <FontAwesomeIcon icon={faShoppingBag} style={{ fontSize: 30, color: "#142A30" }} />
            <span style={{ textAlign: 'center', fontSize: 14, marginTop: 10, color: "#142A30" }}>Vendas de hoje</span>
          </div>
          <div style={{ width: '100%', backgroundColor: '#142A30', display: 'flex', flex: 1, margin: 5, borderRadius: 10, justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }} onClick={() => { location.href = './minhasvendas' }}>
            <FontAwesomeIcon icon={faBookReader} style={{ fontSize: 30, color: "#FFFFFF" }} />
            <span style={{ textAlign: 'center', fontSize: 14, marginTop: 10, color: "#FFFFFF" }}>Histórico de vendas venda</span>
          </div>
        </div>

        <DetalhesVendas
          isMobile={isMobile}
          screenWidth={screenWidth}

          totalVenda={totalVenda}
          totalTarifa={totalTarifa}
          valorCashback={valorCashback}
          valorLiquido={valorLiquido}
          codigoAutorizacao={codigoAutorizacao}
          dataTransacao={dataTransacao}
          horaTransacao={horaTransacao}
          vendastatus={vendastatus}

          id={idestabelecimento}
          dataHora={dataTransacao + ' ' + horaTransacao}
          codigo_autorizacao={codigoAutorizacao}
          bandeira={bandeira}
          forma_pagamento={forma_pagamento}
          valor_venda={totalVenda}
          cashback={valorCashback}
          valor_taxa={totalTarifa}
          valor_liquido={valorLiquido}
          valor_liquido_total={valorLiquidoTotal}
          parcelamentosData={parcelamentosData}

          statusImagemAprovacao={statusImagemAprovacao}
        />
      </>
    )
  }

  return (
    <>
      <div style={{ maxWidth: '1920px', height: '100vh', display: "flex" }}>
        <div style={{ backgroundColor: '#262626' }}>
          <Menu page="xloja" menuOpen={menuOpen} setMenuOpen={setMenuOpen} />
        </div>
        <div style={{ overflow: 'auto', width: '100%' }}>
          <DetalhesVendas
            isMobile={isMobile}
            screenWidth={screenWidth}

            totalVenda={totalVenda}
            totalTarifa={totalTarifa}
            valorCashback={valorCashback}
            valorLiquido={valorLiquido}
            codigoAutorizacao={codigoAutorizacao}
            dataTransacao={dataTransacao}
            horaTransacao={horaTransacao}
            vendastatus={vendastatus}

            id={idestabelecimento}
            dataHora={dataTransacao + ' ' + horaTransacao}
            codigo_autorizacao={codigoAutorizacao}
            bandeira={bandeira}
            forma_pagamento={forma_pagamento}
            valor_venda={totalVenda}
            cashback={valorCashback}
            valor_taxa={totalTarifa}
            valor_liquido={valorLiquido}
            valor_liquido_total={valorLiquidoTotal}
            parcelamentosData={parcelamentosData}

            statusImagemAprovacao={statusImagemAprovacao}
          />
        </div>
      </div>
    </>
  )

};

export default TelaXloja01Areaparceiros;